/// <reference path="../../../../typings/browser.d.ts" />
import { ServiceName, Untyped } from '@deltasierra/shared';
import { LinkModelType, MvAuth } from '../../account/mvAuth';
import { $locationSID, $qSID, $routeSID } from '../../common/angularData';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService as i18nService } from '../../i18n/i18nService';

export const integrationServiceSID = 'integrationService';

export interface IntegrationService {
    applyServiceAuthorizationToScope($scope: ng.IScope, serviceName: ServiceName, serviceDisplayName: Untyped): void;
}

export interface ServiceAuthorization {
    hasAccess: boolean;
    serviceAccountName: string | null;
    model?: { id?: number; $promise: ng.IPromise<Untyped> };
    type: LinkModelType;
    initAuth(): void;
    getHasAccess(): void;
    authorize(): void;
    deauthorize(): void;
}

// TODO: Replace service that takes scope and sprinkles it with magic, with something like a simple class that the consumer just creates and attaches itself

angular.module('app').service(integrationServiceSID, [
    $qSID,
    $locationSID,
    $routeSID,
    MvAuth.SID,
    MvNotifier.SID,
    i18nService.SID,
    function (
        $q: ng.IQService,
        $location: ng.ILocationService,
        $route: Untyped,
        mvAuth: MvAuth,
        mvNotifier: MvNotifier,
        I18nService: i18nService,
    ) {
        const exports = {
            applyServiceAuthorizationToScope(
                $scope: ng.IScope & ServiceAuthorization,
                serviceName: ServiceName,
                serviceDisplayName: string,
            ) {
                $scope.hasAccess = false;
                $scope.serviceAccountName = null;
                // $scope.model
                // $scope.type

                function getData() {
                    return $scope.getHasAccess();
                }

                function displayErrorIfRequired() {
                    const params = $location.search();
                    const errorMessage = params.errorMessage;
                    const serviceId = params.serviceId;
                    if (serviceId && serviceId == serviceName && errorMessage) {
                        mvNotifier.unexpectedError(
                            I18nService.text.integration.notifyCouldNotAuthorizeService({
                                errorMessage,
                                serviceDisplayName,
                            }),
                        );
                    }
                }

                function getModelId(): number | null | undefined {
                    return $scope.model ? $scope.model.id : null;
                }

                $scope.initAuth = () => {
                    const deferred = $q.defer<void>();
                    let prom: ng.IPromise<void> = deferred.promise;
                    prom = prom.then(() => {
                        displayErrorIfRequired();
                        if (($scope.type as any) == 'application' || $scope.model) {
                            if ($scope.model && $scope.model.$promise) {
                                return $scope.model.$promise.then(getData);
                            } else {
                                return getData();
                            }
                        }
                    });
                    deferred.resolve();
                    return prom;
                };

                // Really should be called checkHasAccess since it mutates
                $scope.getHasAccess = () =>
                    mvAuth.getServiceAccessInfo(serviceName, $scope.type, getModelId()).then(
                        accountInfo => {
                            $scope.hasAccess = accountInfo.result;
                            $scope.serviceAccountName =
                                accountInfo.serviceAccountName || I18nService.text.common.unknown();
                        },
                        err => {
                            mvNotifier.unexpectedErrorWithData(
                                I18nService.text.integration.notifyAccessCheckFailed({ serviceDisplayName }),
                                err,
                            );
                        },
                    );

                $scope.authorize = () =>
                    mvAuth.authorizeService(serviceName, $location.path(), $scope.type, getModelId());

                $scope.deauthorize = () =>
                    mvAuth.deauthorizeService(serviceName, $scope.type, getModelId()).then(
                        () => {
                            mvNotifier.notify(
                                I18nService.text.integration.notifyServiceDeauthorized({ serviceDisplayName }),
                            );
                            $route.reload();
                        },
                        res => {
                            mvNotifier.unexpectedErrorWithData(
                                I18nService.text.integration.notifyCouldNotDeauthorizeService({ serviceDisplayName }),
                                res,
                            );
                        },
                    );
            },
        };

        return exports;
    },
]);
