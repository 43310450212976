/// <reference path="../../../../typings/browser.d.ts" />

import {
    linkToTemplate,
    TemplateGroupItem,
    BuilderTemplate,
    BuilderTemplateFormat,
    getPlatformIcon,
    linkToBuildableTemplateBuilder,
} from '@deltasierra/shared';

import * as linq from 'linq';
import { MvIdentity } from '../../account/mvIdentity';
import { $scopeSID, ExpressionCallback, simpleModal } from '../../common/angularData';
import { $modalSID } from '../../common/angularUIBootstrapData';
import { GET_CONFIG_QUERY } from '../../common/config';
import { GetConfig } from '../../common/config/__graphqlTypes/GetConfig';
import { Future } from '../../common/Future';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { GraphqlService } from '../../graphql/GraphqlService';
import { convertIdToUniversalNodeId } from '../../graphql/utils';
import { I18nService } from '../../i18n';
import { mapBuilderTemplateWithBasicAssociationsToTemplateGroupItem } from '../../templateGallery/templateGroup';
import { ModalService } from '../../typings/angularUIBootstrap/modalService';
import { BuilderTemplateApiClient } from '../builderTemplateApiClient';
import {
    buildableTemplateDuplicateRequestedEvent,
    buildableTemplateDeleteRequestedEvent,
    templateDeletedEvent,
    templateDraftModeUpdatedEvent,
    templateMetadataUpdatedEvent,
    buildableTemplateTogglePublishStatusRequestedEvent,
} from './admin/templateAdminEvents';
import { TemplateAdminCategoriesController } from './admin/templateAdminMetadataController';

interface TemplateThumbnail {
    title: string;
    templateUrl: string;
    thumbnailUrl: string | null;
    formats: TemplateThumbnailFormat[];
}

interface TemplateThumbnailFormat {
    title: string;
    name?: string;
    className: string;
}

export class TemplateThumbnailController {
    public static readonly SID = 'TemplateThumbnailController';

    public static readonly $inject: string[] = [
        $scopeSID,
        $modalSID,
        MvIdentity.SID,
        I18nService.SID,
        MvNotifier.SID,
        InteractionUtils.SID,
        BuilderTemplateApiClient.SID,
        GraphqlService.SID,
    ];

    public isFaded: boolean | undefined;

    public plannerId: number | undefined;

    public template!: TemplateGroupItem;

    public thumbnail?: TemplateThumbnail | null;

    public showMenu?: () => boolean;

    public manageBuildableTemplatesLink?: string;

    public linkToTemplate?: ExpressionCallback<{ template: BuilderTemplate }, string>;

    public readonly toggleTemplateDraftMode: Future<void, unknown>;

    public readonly submitDeleteTemplate: Future<void, unknown>;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $scope: ng.IScope,
        private readonly $modal: ModalService,
        private readonly mvIdentity: MvIdentity,
        private readonly i18nService: I18nService,
        private readonly mvNotifier: MvNotifier,
        private readonly interactionUtils: InteractionUtils,
        private readonly builderTemplateApiClient: BuilderTemplateApiClient,
        private readonly graphqlService: GraphqlService,
    ) {
        if (this.showMenu === undefined) {
            // If the directive did not receive a "show-menu" attribute, default it
            this.showMenu = () => true;
        }

        $scope.$watch(
            () => this.template,
            template => {
                this.thumbnail = this.templateToThumbnail(template as BuilderTemplate);
            },
        );
        // Admin functions can change certain attributes, so we need to watch for those changes.
        // (Relies on the other watch to update the thumbnail data)
        templateMetadataUpdatedEvent.on(this.$scope, (event, metadata) => {
            /**
             * Because this event is broadcast from a modal
             * we need to filter the event to those relevant to this template.
             */
            if (this.template && metadata.builderTemplate.id === this.template.id) {
                this.template = mapBuilderTemplateWithBasicAssociationsToTemplateGroupItem(metadata.builderTemplate);
            }
        });

        this.toggleTemplateDraftMode = this.interactionUtils.createFuture(this.i18nService.text.common.save(), () =>
            this.builderTemplateApiClient
                .updateBuilderTemplateDraftMode(this.template.id, { isDraft: !this.template.isDraft })
                .then(builderTemplate => {
                    this.mvNotifier.success(this.i18nService.text.build.templateAdmin.templateUpdated());
                    templateDraftModeUpdatedEvent.emit(this.$scope, {
                        builderTemplate,
                    });
                }),
        );

        this.submitDeleteTemplate = this.interactionUtils.createFuture(this.i18nService.text.common.delete(), () =>
            this.builderTemplateApiClient.deleteBuilderTemplate(this.template.id).then(() => {
                this.mvNotifier.success(this.i18nService.text.build.templateDeleted());
                templateDeletedEvent.emit(this.$scope, {
                    builderTemplateId: this.template.id,
                });
            }),
        );
    }

    public openTemplateCategoryAdmin(): void {
        simpleModal(
            this.$modal,
            TemplateAdminCategoriesController,
            '/partials/contentBuilder/templateThumbnailDirective/admin/templateAdminMetadata.jade',
            {
                template: this.template,
            },
        );
    }

    public shouldShowTemplateMenu(): boolean {
        return (
            this.mvIdentity.isManager() &&
            !!this.showMenu &&
            this.showMenu() &&
            this.template.currentRevisionId === null &&
            this.template.buildableTemplateId === null
        );
    }

    public shouldShowBuildableTemplateMenu(): boolean {
        return (
            this.mvIdentity.isManager() &&
            !!this.showMenu &&
            this.showMenu() &&
            this.template.currentRevisionId !== null
        );
    }

    public async $onInit(): Promise<void> {
        const environmentConfig = await this.getEnvironmentConfig();

        if (this.template.buildableTemplateId) {
            this.manageBuildableTemplatesLink = linkToBuildableTemplateBuilder(
                this.template.documentFormat,
                this.template.buildableTemplateId,
                convertIdToUniversalNodeId('client', this.template.clientId),
                environmentConfig.config.appFrontendUrl,
            );
        }
    }

    public duplicateTemplate(): void {
        buildableTemplateDuplicateRequestedEvent.emit(this.$scope, {
            clientId: this.template.clientId,
            templateId: this.template.graphqlId,
        });
    }

    public deleteTemplate(): void {
        buildableTemplateDeleteRequestedEvent.emit(this.$scope, {
            templateId: this.template.graphqlId,
        });
    }

    public togglePublishStatus(): void {
        buildableTemplateTogglePublishStatusRequestedEvent.emit(this.$scope, {
            clientId: this.template.clientId,
            isDraft: this.template.isDraft,
            templateId: this.template.graphqlId,
        });
    }

    private templateToThumbnail(template: BuilderTemplate): TemplateThumbnail | null {
        const thumbnail = template
            ? {
                  formats: this.templateFormatsToThumbnailFormats(template.formats || []),
                  templateUrl: this.linkToTemplate
                      ? this.linkToTemplate({ template })
                      : linkToTemplate(template.documentFormat, template.id, this.plannerId),
                  thumbnailUrl: template.compositeImage ? template.compositeImage.thumb256x256url! : null,
                  title: template.title,
              }
            : null;

        return thumbnail || null;
    }

    private templateFormatsToThumbnailFormats(formats: BuilderTemplateFormat[]): TemplateThumbnailFormat[] {
        const result = linq
            .from(formats)
            .groupBy(
                format => format.platform!.name,
                format => format,
                (platformName, group) => {
                    const formatNames = group
                        .toArray()
                        .map(format => format.label)
                        .sort();

                    return {
                        className: getPlatformIcon(platformName),
                        name: platformName,
                        title: `${group.first().groupLabel} - ${formatNames.join(', ')}`,
                    };
                },
            )
            .toArray();

        return result;
    }

    private async getEnvironmentConfig(): Promise<GetConfig> {
        const gqlClient = this.graphqlService.getClient();

        const configResult = await gqlClient.query<GetConfig>({
            fetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true,
            query: GET_CONFIG_QUERY,
        });

        if (configResult.errors) {
            throw new Error('Failed to fetch config');
        }

        return configResult.data;
    }
}

angular.module('app').controller(TemplateThumbnailController.SID, TemplateThumbnailController);
