import { isNotNullOrUndefined, AssignedLocation, LocationId, ReportableService } from '@deltasierra/shared';


import { gql } from '@apollo/client';
import { SingleSearchCriteria } from '../../reports/reportPage/controller';
import { AppConnectionStatus, PlatformAction } from '../../../../__graphqlTypes/globalTypes';
import { LocationAppsConfigurationFragment } from './__graphqlTypes/LocationAppsConfigurationFragment';
import { ClientPlatformConfigurationFragment } from './__graphqlTypes/ClientPlatformConfigurationFragment';

export const LOCATION_APPS_CONFIGURATION_PLATFORM = gql`
    fragment LocationAppsConfigurationFragment on App {
        id
        status
        platform {
            id
            name
        }
    }
`;

export const CLIENT_PLATFORM_CONFIGURATION_FRAGMENT = gql`
    fragment ClientPlatformConfigurationFragment on Platform {
        id
        name
        actions
    }
`;


export interface ILocationStatsService<T, ME> {
    readonly serviceName: ReportableService | 'fitwareMemberEngager' | 'internal';
    readonly displayName: string;

    getStatsForLocation(searchCriteria: SingleSearchCriteria): angular.IHttpPromise<T>;

    canConfigure(locationId: LocationId): angular.IPromise<boolean>;

    combineStats(stats: ME[]): ME;

    isPlatformEnabled(location: AssignedLocation, clientPlatforms?: ClientPlatformConfigurationFragment[]): boolean;

    isPlatformConfigured(location: AssignedLocation, locationApps?: LocationAppsConfigurationFragment[]): boolean;
}

export interface CanConfigureResponse {
    canConfigure: boolean;
}

export abstract class BaseLocationStatsService<T, ME> implements ILocationStatsService<T, ME> {
    private readonly platformCheckWhitelist = ['internal'];

    protected constructor(protected $http: ng.IHttpService) {}

    public getStatsForLocation(searchCriteria: SingleSearchCriteria): angular.IHttpPromise<T> {
        // Need to pass dates as strings, since Angular's date-serializing is broken and adds quote marks.
        // See: https://github.com/angular/angular.js/issues/8150
        return this.$http.get(`/api/stats/${this.serviceName}/location/${searchCriteria.location.id}`, {
            params: {
                endDate: this.dateParameterToString(searchCriteria.endDate),
                startDate: this.dateParameterToString(searchCriteria.startDate),
            },
        });
    }

    public dateParameterToString(date: Date): string {
        return date.toISOString();
    }

    public canConfigure(locationId: LocationId): angular.IPromise<boolean> {
        return this.$http
            .get<CanConfigureResponse>(`/api/auth/${this.serviceName}/location/${locationId}/canConfigure`) // Hmm, auth with stats?
            .then((res: ng.IHttpPromiseCallbackArg<CanConfigureResponse>) => res.data!.canConfigure);
    }

    public isPlatformEnabled(location: AssignedLocation, clientPlatforms: ClientPlatformConfigurationFragment[]): boolean {
        if (this.platformCheckWhitelist.includes(this.serviceName)) {
            return true;
        }

        const mappedServiceName = this.mapServiceName(this.serviceName);
        const matchingPlatform = clientPlatforms.find(x => x.name === mappedServiceName);
        return !!matchingPlatform && matchingPlatform.actions.includes(PlatformAction.REPORTING);
    }

    public isPlatformConfigured(location: AssignedLocation, locationApps: LocationAppsConfigurationFragment[] | undefined): boolean {
        if (this.platformCheckWhitelist.includes(this.serviceName)) {
            return true;
        }
        const mappedServiceName = this.mapServiceName(this.serviceName);
        const app = locationApps?.find(x => x.platform.name === mappedServiceName);
        return isNotNullOrUndefined(app) && app.status !== AppConnectionStatus.NOT_CONNECTED;
    }

    private mapServiceName(serviceName: ReportableService | 'fitwareMemberEngager' | 'internal') {
        return serviceName === 'fitwareMemberEngager' ? 'fitware' : serviceName;
    }

    public abstract readonly displayName: string;

    public abstract readonly serviceName: ReportableService | 'fitwareMemberEngager' | 'internal';

    public abstract combineStats(stats: ME[]): ME;
}
