import { Translate } from '@deltasierra/components';
import { ServiceName, URLParamsBuilder } from '@deltasierra/shared';
import * as React from 'react';
import { Link } from 'react-router-dom';
import ArrowForward from '@material-ui/icons/ArrowForward';

export interface ConnectPageLinkProps {
    platform?: ServiceName;
    locationId?: string;
    customMessage?: string;
    showArrow?: boolean;
}
export const ConnectPageLink: React.FC<ConnectPageLinkProps> = ({ customMessage, locationId, platform, showArrow = false }) => {
    const searchParams = new URLParamsBuilder();

    if (platform) {
        searchParams.add('platform', platform);
    }

    if (locationId) {
        searchParams.add('locationId', locationId);
    }

    return (
        <Link to={{ pathname: '/connect', search: searchParams.build() }}>
            {customMessage ? customMessage : <Translate keyId="COMMON.CONNECT_PAGE.CONNECT_PAGE" />}
            {showArrow && <ArrowForward style={{ marginLeft: '5px', position: 'fixed' }} />}
        </Link>
    );
};
ConnectPageLink.displayName = 'ConnectPageLink';
