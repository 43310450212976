/// <reference path="../../../typings/browser.d.ts" />
import {
    ActivityStats,
    ChannelStats,
    ClientId,
    InternalStats,
    LocationId,
    Omit,
    ReportableService,
} from '@deltasierra/shared';
import { DataUtils } from '../common/dataUtils';
import { BaseLocationStatsService } from '../integration/stats/common';
import IHttpService = angular.IHttpService;

export type CombinedInternalStats = Omit<InternalStats, 'lastLogin'> & {
    lastLogin: null;
};

export class InternalStatsService extends BaseLocationStatsService<InternalStats, CombinedInternalStats> {
    static SID = 'internalStatsService';

    serviceName: ReportableService | 'internal' = 'internal';

    displayName = 'Digital Stack';

    static readonly $inject: string[] = ['$http', DataUtils.SID];

    constructor($http: IHttpService, private dataUtils: DataUtils) {
        super($http);
    }

    getLocationPerformanceStats(
        clientId: ClientId,
        locationId: LocationId,
        startDate: Date,
        endDate: Date,
    ): angular.IHttpPromise<any> {
        return this.$http.get(`/api/stats/top/client/${clientId}/location/${locationId}`, {
            params: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            },
        });
    }

    combineStats(stats: CombinedInternalStats[]): CombinedInternalStats {
        const initial: CombinedInternalStats = {
            planners: {
                activities: [],
                channels: [],
                count: 0,
                supported: 0,
                general: 0,
                selfService: 0,
            },
            specialRequests: {
                count: 0,
            },
            publishedArtifacts: {
                countByManagers: 0,
                countByClients: 0,
                count: 0,
            },
            lastLogin: null,
        };
        return stats.reduce(this.reduceStats.bind(this), initial);
    }

    private reduceStats(
        previousValue: CombinedInternalStats,
        currentValue: CombinedInternalStats,
        currentIndex: number,
        array: CombinedInternalStats[],
    ): CombinedInternalStats {
        const result: CombinedInternalStats = {
            planners: {
                activities: (previousValue.planners.activities || []).slice(), // Needs adjustment
                channels: (previousValue.planners.channels || []).slice(), // Needs adjustment
                count: previousValue.planners.count + currentValue.planners.count,
                supported: previousValue.planners.supported + currentValue.planners.supported,
                general: previousValue.planners.general + currentValue.planners.general,
                selfService: previousValue.planners.selfService + currentValue.planners.selfService,
            },
            specialRequests: {
                count: previousValue.specialRequests.count + currentValue.specialRequests.count,
            },
            publishedArtifacts: {
                countByManagers:
                    previousValue.publishedArtifacts.countByManagers + currentValue.publishedArtifacts.countByManagers,
                countByClients:
                    previousValue.publishedArtifacts.countByClients + currentValue.publishedArtifacts.countByClients,
                count: previousValue.publishedArtifacts.count + currentValue.publishedArtifacts.count,
            },
            lastLogin: null,
        };
        for (const activity of currentValue.planners.activities) {
            const existingActivity: ActivityStats | null = this.dataUtils.findBy(
                'id',
                result.planners.activities,
                activity.id,
            );
            if (existingActivity) {
                existingActivity.count += activity.count; // Dodgy; mutating input data
            } else {
                result.planners.activities.push(activity);
            }
        }
        for (const channel of currentValue.planners.channels) {
            const existingChannel: ChannelStats | null = this.dataUtils.findBy(
                'id',
                result.planners.channels,
                channel.id,
            );
            if (existingChannel) {
                existingChannel.count += channel.count; // Dodgy; mutating input data
            } else {
                result.planners.channels.push(channel);
            }
        }
        return result;
    }
}

angular.module('app').service('internalStatsService', InternalStatsService);
