/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/// <reference path="../../../../typings/browser.d.ts" />
import { gql } from '@apollo/client';
import {
    AssignedLocation,
    assignedLocationToLocationIdHierarchy,
    FacebookStatsForLocation,
    LocationId,
    LocationIdHierarchy,
    MailchimpReportEntry,
    ReportableService,
    SendgridEmailCampaignReport,
    chunk,
    chunkedAsyncMap,
    flatten,
    pluck,
    ErrorCode,
    ServerError,
    CurrencyCode,
    ReportSummary,
    ConversionStats,
    BatchStatsEntry,
    FitwareMemberEngagerStatsForLocation,
    BrandAwarenessMetrics,
    BrandAwarenessStats,
    ALL_GOOGLE_ADWORDS_REPORT_BY_TYPES,
    ALL_GOOGLE_ADWORDS_REPORT_TYPES,
    GoogleAdWordsReportByType,
    GoogleAdWordsReportType,
    ConversionsMetrics,
    ConversionsStats,
    AdWordsReportDataOutput,
    InstagramStatsDto,
    InternalStats,
    TwitterReportStats,
} from '@deltasierra/shared';
import { isNullOrUndefined, Untyped } from '@deltasierra/type-utilities';
import { noop } from '@deltasierra/object-utilities';
import * as linq from 'linq';
import moment from 'moment';
import { stripIndents } from 'common-tags';
import { MvIdentity } from '../../account/mvIdentity';
import { AgencyApiClient } from '../../agencies/agencyApiClient';
import { ClientResource, MvClientResource, mvClientResourceSID } from '../../clients/mvClientResource';
import { $filterSID, $locationSID, $qSID, $scopeSID, $timeoutSID } from '../../common/angularData';
import { $modalSID } from '../../common/angularUIBootstrapData';
import { DataUtils } from '../../common/dataUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n';
import {
    CampaignMonitorStats,
    CampaignMonitorStatsEntry,
    CampaignMonitorStatsService,
} from '../../integration/stats/campaignMonitorStatsService';
import {
    CLIENT_PLATFORM_CONFIGURATION_FRAGMENT,
    ILocationStatsService,
    LOCATION_APPS_CONFIGURATION_PLATFORM,
} from '../../integration/stats/common';
import {
    FacebookMarketingStatsForLocation,
    FacebookMarketingStatsService,
    SimpleFacebookAdSetStats,
} from '../../integration/stats/facebookMarketingStatsService';
import { FacebookStatsService } from '../../integration/stats/facebookStatsService';
import {
    FitwareMemberEngagerStatsService,
    GroupedFitwareMemberEngagerStats,
} from '../../integration/stats/fitwareMemberEngagerStatsService';
import { FitwareStats, FitwareStatsService } from '../../integration/stats/fitwareStatsService';
import { GoogleAdWordsStatsService } from '../../integration/stats/googleAdWordsStatsService';
import { GoogleStats, GoogleStatsService } from '../../integration/stats/googleStatsService';
import { InstagramStatsService } from '../../integration/stats/instagramStatsService';
import { MailchimpStatsService } from '../../integration/stats/mailchimpStatsService';
import { TwitterStatsService } from '../../integration/stats/twitterStatsService';
import { IntroWrapper } from '../../intro/introWrapper';
import { MvLocation } from '../../locations/mvLocation';
import { ModalService } from '../../typings/angularUIBootstrap/modalService';
import { PlatformStats, ReportTable, StatsEntry } from '../reportTable/reportTable';
import { createCampaignMonitorReportTable } from '../reportTables/campaignMonitor';
import { CreateReportTableOptions } from '../reportTables/common';
import { createConversionSummaryReportTable } from '../reportTables/conversionSummary';
import { createFacebookReportTable } from '../reportTables/facebook';
import { createFacebookMarketingReportTable } from '../reportTables/facebookMarketing';
import {
    createFitwareConversionsReportTable,
    createFitwareLeadsReportTable,
    createFitwareSalesReportTable,
} from '../reportTables/fitware';
import {
    createFitwareMemberEngagerReportTable,
    FitwareMemberEngagerTableRowStatsType,
    FitwareMemberEngagerTableRowType,
} from '../reportTables/fitwareMemberEngager';
import { Formatters } from '../reportTables/formatters';
import { createGoogleReportTable } from '../reportTables/google';
import {
    createGoogleAdWordsBrandAwarenessReportTable,
    createGoogleAdWordsConversionsReportTable,
} from '../reportTables/googleAdWords';
import { createInstagramReportTable } from '../reportTables/instagram';
import { createInternalReportTable } from '../reportTables/internal';
import { createMailchimpReportTable } from '../reportTables/mailchimp';
import { createTwitterReportTable } from '../reportTables/twitter';
import { mvReportSummaryResourceSID } from '../summary/mvReportSummaryResource';
import { createSendGridReportTable } from '../reportTables/sendgrid';
import { SendGridStatsService } from '../../integration/stats/sendGridStatsService';
import { GraphqlService } from '../../graphql/GraphqlService';
import { relayConnectionToArray } from '../../graphql/utils';
import { IntroService } from './../../intro/introService';
import { InternalStatsService } from './../internalStatsService';
import { GetAppsForLocations, GetAppsForLocations_locations_edges_node } from './__graphqlTypes/GetAppsForLocations';
import IFilterOrderBy = angular.IFilterOrderBy;
import IScope = angular.IScope;
import ILocationService = angular.ILocationService;
import IFilterService = angular.IFilterService;
import ITimeoutService = angular.ITimeoutService;
import IQService = angular.IQService;
import IPromise = angular.IPromise;

const GET_APPS_FOR_LOCATIONS_QUERY = gql`
    query GetAppsForLocations($locationIds: [ID!]!) {
        locations(ids: $locationIds) {
            edges {
                node {
                    id
                    legacyId
                    client {
                        id
                        platforms {
                            edges {
                                node {
                                    id
                                    ...ClientPlatformConfigurationFragment
                                }
                            }
                        }
                    }
                    apps {
                        edges {
                            node {
                                id
                                ...LocationAppsConfigurationFragment
                            }
                        }
                    }
                }
            }
        }
    }
    ${CLIENT_PLATFORM_CONFIGURATION_FRAGMENT}
    ${LOCATION_APPS_CONFIGURATION_PLATFORM}
`;

// eslint-disable-next-line camelcase
type LocationApp = GetAppsForLocations_locations_edges_node;

export interface SingleSearchCriteria {
    location: AssignedLocation;
    startDate: Date;
    endDate: Date;
}

export interface MultiSearchCriteria {
    locations: LocationIdHierarchy[];
    startDate: Date | undefined;
    endDate: Date | undefined;
}

interface StatsMap {
    campaignMonitor: PlatformStats<CampaignMonitorStatsEntry>;
    facebook: PlatformStats<FacebookStatsForLocation>;
    facebookMarketing: PlatformStats<SimpleFacebookAdSetStats>;
    fitware: PlatformStats<FitwareStats>;
    fitwareMemberEngager: PlatformStats<FitwareStats>;
    google: PlatformStats<GoogleStats>;
    googleAdWords: PlatformStats<AdWordsReportDataOutput>;
    instagram: PlatformStats<InstagramStatsDto>;
    internal: PlatformStats<InternalStats>;
    mailchimp: PlatformStats<MailchimpReportEntry>;
    reportSummaries: ReportSummary[];
    sendgrid: PlatformStats<SendgridEmailCampaignReport>;
    twitter: PlatformStats<TwitterReportStats>;
}

interface ChartsEntry {
    data: Untyped[]; // TODO: proper type
    labels: string[];
    colours: string[];
}

interface ChartsMap {
    facebook: ChartsEntry | null;
    google: ChartsEntry | null;
    internal: {
        activities: ChartsEntry; // Not used
        channels: ChartsEntry;
        support: ChartsEntry;
    } | null;
}

interface ReportTableMap {
    campaignMonitor: ReportTable<StatsEntry<CampaignMonitorStatsEntry>, CampaignMonitorStatsEntry>;
    conversions: ReportTable<StatsEntry<ConversionStats>, ConversionStats>;
    facebook: ReportTable<StatsEntry<FacebookStatsForLocation>, FacebookStatsForLocation>;
    facebookMarketing: ReportTable<StatsEntry<SimpleFacebookAdSetStats>, SimpleFacebookAdSetStats>;
    fitwareLeads: ReportTable<StatsEntry<FitwareStats>, FitwareStats>;
    fitwareSales: ReportTable<StatsEntry<FitwareStats>, FitwareStats>;
    fitwareConversions: ReportTable<StatsEntry<FitwareStats>, FitwareStats>;
    fitwareMemberEngager: ReportTable<FitwareMemberEngagerTableRowStatsType, FitwareMemberEngagerTableRowType>;
    google: ReportTable<StatsEntry<GoogleStats>, GoogleStats>;
    googleAdWords: {
        brandAwareness: {
            byAd: ReportTable<StatsEntry<BrandAwarenessStats>, BrandAwarenessMetrics>;
            byKeyword: ReportTable<StatsEntry<BrandAwarenessStats>, BrandAwarenessMetrics>;
        };
        conversions: {
            byAd: ReportTable<StatsEntry<ConversionsStats>, ConversionsMetrics>;
            byKeyword: ReportTable<StatsEntry<ConversionsStats>, ConversionsMetrics>;
        };
    };
    instagram: ReportTable<StatsEntry<InstagramStatsDto>, InstagramStatsDto>;
    internal: ReportTable<StatsEntry<InternalStats>, InternalStats>;
    mailchimp: ReportTable<StatsEntry<MailchimpReportEntry>, MailchimpReportEntry>;
    sendgrid: ReportTable<StatsEntry<SendgridEmailCampaignReport>, SendgridEmailCampaignReport>;
    twitter: ReportTable<StatsEntry<TwitterReportStats>, TwitterReportStats>;
}

type CountPerChannel = {
    [key in ReportableService]: number;
} & {
    all: number;
    fitwareMemberEngager: number;
    internal: number;
    reportSummaries: number;
};

interface HasCurrencyCode {
    currencyCode: CurrencyCode;
}

function passThrough<T>(stats: T): T {
    return stats;
}

const CONVERSIONS_TABLE_ENABLED = false; // https://github.com/ltnetwork/digitalstack/pull/322#issuecomment-426498711
const MAX_CONCURRENT_REQUESTS = 6;

export class ReportPageController {
    public static SID = 'ReportPageController';

    public currentLocationId!: LocationId;

    public currentLocation: LocationIdHierarchy | null = null;

    public locations: LocationIdHierarchy[] | null = null;

    public introReport: IntroWrapper;

    public errors: string[] = [];

    public stats: Partial<StatsMap> = {
        campaignMonitor: undefined,
        facebook: undefined,
        facebookMarketing: undefined,
        fitware: undefined,
        google: undefined,
        googleAdWords: undefined,
        instagram: undefined,
        internal: undefined,
        reportSummaries: undefined,
        twitter: undefined,
    };

    public reportTables!: ReportTableMap;

    public showErrors = false;

    public searchCriteria: MultiSearchCriteria = {
        endDate: new Date(),
        locations: [],
        startDate: new Date(),
    };

    public searched: MultiSearchCriteria | null = null;

    public isSearching = false;

    public isLoadingAppsForLocation = false;

    public datePickerIsOpen = {
        endDate: false,
        startDate: false,
    };

    public loading: CountPerChannel = this.initialiseCountPerChannel();

    public progress: CountPerChannel = this.initialiseCountPerChannel();

    public queryCount = 0;

    public charts: ChartsMap = {
        facebook: null,
        google: null,
        internal: null,
    };

    public chartColours = ['#428bca', '#ff4f4c', '#ff78d6'];

    public MESSAGE_REPORT_SUMMARIES_HIDDEN = 'All: report summaries are not shown for multiple location reports.';

    public LIMIT_LOCATIONS_TO_DISPLAY = 5;

    public LIMIT_ACTIVITIES_TO_DISPLAY = 3;

    public LIMIT_CHANNELS_TO_DISPLAY = 3;

    public selectedAdWordsReportType: GoogleAdWordsReportType = 'conversions';

    public adWordsReportTypes = ALL_GOOGLE_ADWORDS_REPORT_TYPES;

    public adWordsReportTypeNames: { [reportType in GoogleAdWordsReportType]: string } = {
        brandAwareness: this.i18n.text.report.brandAwareness(),
        conversions: this.i18n.text.report.conversions(),
    };

    public selectedAdWordsReportByType: GoogleAdWordsReportByType = 'ad';

    public adWordsReportByTypes = ALL_GOOGLE_ADWORDS_REPORT_BY_TYPES;

    public adWordsReportByTypeNames: { [reportByType in GoogleAdWordsReportByType]: string } = {
        ad: this.i18n.text.report.byAd(),
        keyword: this.i18n.text.report.byKeyword(),
    };

    public readonly reportingChannelProcessors: Array<
        (queryCount: number, locations: AssignedLocation[], locationApps: LocationApp[]) => ng.IPromise<void>
    > = [
        (queryCount, locations, locationApps) => this.getInternalStats(queryCount, locations, locationApps),
        (queryCount, locations, locationApps) => this.getFacebookStats(queryCount, locations, locationApps),
        (queryCount, locations, locationApps) => this.getFacebookMarketingStats(queryCount, locations, locationApps),
        (queryCount, locations, locationApps) => this.getGoogleAdWordsStats(queryCount, locations, locationApps),
        (queryCount, locations, locationApps) => this.getGoogleStats(queryCount, locations, locationApps),
        (queryCount, locations, locationApps) => this.getCampaignMonitorStats(queryCount, locations, locationApps),
        async (queryCount, locations, locationApps) => this.getMailchimpStats(queryCount, locations, locationApps),
        async (queryCount, locations, locationApps) => this.getSendGridStats(queryCount, locations, locationApps),
        (queryCount, locations, locationApps) => this.getInstagramStats(queryCount, locations, locationApps),
        (queryCount, locations, locationApps) => this.getTwitterStats(queryCount, locations, locationApps),
        (queryCount, locations) => this.getReportSummaries(queryCount, locations),
        (queryCount, locations, locationApps) => this.getFitwareMemberEngagerStats(queryCount, locations, locationApps),
        // (queryCount, locations) => this.getFitwareStats(queryCount), // DS-2343
    ];

    private readonly conversionStatsServices: Array<ILocationStatsService<any, any>> = [this.fitwareStatsService];

    private readonly orderBy: IFilterOrderBy;

    private gracefulErrorCodes = Object.freeze([
        ErrorCode.NoStatsDataError,
        ErrorCode.ServiceCredentialsMissingError,
        ErrorCode.ServiceConfigurationError,
        ErrorCode.ServiceAuthenticationError,
        ErrorCode.ServiceDisabledForClientError,
    ]);

    private expandedLocationIds: {
        fitwareMemberEngager: LocationId[];
        googleAdWords: LocationId[];
    } = {
        fitwareMemberEngager: [],
        googleAdWords: [],
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [
        $scopeSID,
        $locationSID,
        $filterSID,
        $timeoutSID,
        $qSID,
        $modalSID,
        MvNotifier.SID,
        MvIdentity.SID,
        MvLocation.SID,
        AgencyApiClient.SID,
        mvClientResourceSID,
        CampaignMonitorStatsService.SID,
        MailchimpStatsService.SID,
        SendGridStatsService.SID,
        FacebookStatsService.SID,
        FacebookMarketingStatsService.SID,
        FitwareStatsService.SID,
        FitwareMemberEngagerStatsService.SID,
        GoogleStatsService.SID,
        GoogleAdWordsStatsService.SID,
        InstagramStatsService.SID,
        InternalStatsService.SID,
        TwitterStatsService.SID,
        mvReportSummaryResourceSID,
        DataUtils.SID,
        IntroService.SID,
        I18nService.SID,
        GraphqlService.SID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $scope: IScope,
        private readonly $location: ILocationService,
        private readonly $filter: IFilterService,
        private readonly $timeout: ITimeoutService,
        private readonly $q: IQService,
        private readonly $modal: ModalService,
        private readonly mvNotifier: MvNotifier,
        public readonly identity: MvIdentity,
        private readonly mvLocation: MvLocation,
        agencyApiClient: AgencyApiClient,
        private readonly clientResource: MvClientResource,
        private readonly campaignMonitorStatsService: ILocationStatsService<
            CampaignMonitorStats,
            CampaignMonitorStatsEntry
        >,
        private readonly mailchimpStatsService: MailchimpStatsService,
        private readonly sendGridStatsService: SendGridStatsService,
        private readonly facebookStatsService: ILocationStatsService<
            FacebookStatsForLocation,
            FacebookStatsForLocation
        >,
        private readonly facebookMarketingStatsService: ILocationStatsService<
            FacebookMarketingStatsForLocation,
            SimpleFacebookAdSetStats
        >,
        private readonly fitwareStatsService: ILocationStatsService<FitwareStats, FitwareStats>,
        private readonly fitwareMemberEngagerStatsService: FitwareMemberEngagerStatsService,
        private readonly googleStatsService: ILocationStatsService<GoogleStats, GoogleStats>,
        private readonly googleAdWordsStatsService: ILocationStatsService<
            AdWordsReportDataOutput,
            AdWordsReportDataOutput
        >,
        private readonly instagramStatsService: ILocationStatsService<InstagramStatsDto, InstagramStatsDto>,
        private readonly internalStatsService: ILocationStatsService<InternalStats, InternalStats>,
        private readonly twitterStatsService: ILocationStatsService<TwitterReportStats, TwitterReportStats>,
        private readonly mvReportSummaryResource: Untyped, // TODO: type
        private readonly dataUtils: DataUtils,
        introService: IntroService,
        public readonly i18n: I18nService,
        private readonly graphqlService: GraphqlService,
    ) {
        $scope.$watch('ctrl.currentLocationId', currentLocationId => {
            void this.updateCurrentLocation(currentLocationId as LocationId);
        });
        this.orderBy = $filter('orderBy');
        this.resetStatsAndErrors();
        this.initialiseReportTables();

        this.introReport = introService.setUpAndStartIntro('report', this.$scope, ['#locationsLoaded']);

        const lastMonth = new Date();
        lastMonth.setDate(0);
        const startDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth());
        const endDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1);
        endDate.setSeconds(-1);
        this.searchCriteria.startDate = startDate;
        this.searchCriteria.endDate = endDate;
        agencyApiClient.getHasGoogleAdWordsMarketingContact().catch(error => this.mvNotifier.unexpectedError(error));
    }

    public currentProgressPercentage(): number {
        return (this.currentProgress() / this.progressTotal()) * 100;
    }

    public currentProgress(): number {
        return this.progress.all;
    }

    public progressTotal(): number {
        /*
         * [DS-2888]: The extra step is pre-fetching all locations before reporting on their stats. If more extra steps are added, this
         * value should be updated.
         */
        const extraStepsCount = 1;
        return this.searchCriteria.locations.length * (this.reportingChannelProcessors.length + extraStepsCount);
    }

    // eslint-disable-next-line max-statements
    public async search() {
        const startDate = this.searchCriteria.startDate;
        const endDate = this.searchCriteria.endDate;
        // Check if search criteria date is a valid date object
        if (!startDate || isNaN(startDate.getTime())) {
            this.mvNotifier.expectedError(this.i18n.text.report.invalidStartDate());
            return;
        }
        if (!endDate || isNaN(endDate.getTime())) {
            this.mvNotifier.expectedError(this.i18n.text.report.invalidEndDate());
            return;
        }
        if (endDate < startDate) {
            this.mvNotifier.expectedError(this.i18n.text.report.endDateBeforeStartDate());
            return;
        }
        const maxDiff = 8035200000;
        const diff = endDate.getTime() - startDate.getTime();
        if (diff > maxDiff) {
            this.mvNotifier.expectedError(this.i18n.text.report.cantReportMoreThanThreeMonths());
            return;
        }
        const now = new Date();
        if (startDate >= now) {
            this.mvNotifier.expectedError(this.i18n.text.report.startDateBeforeToday());
            return;
        }
        if (!this.locations) {
            this.mvNotifier.expectedError(this.i18n.text.report.waitForLocations());
            return;
        }
        if (!this.searchCriteria.locations || this.searchCriteria.locations.length === 0) {
            this.mvNotifier.expectedError(this.i18n.text.report.selectAtLeastOneLocation());
            return;
        }

        this.resetReportTablesExpandedLocations();

        this.queryCount++;
        this.searched = {
            endDate: this.searchCriteria.endDate,
            locations: this.searchCriteria.locations,
            startDate: this.searchCriteria.startDate,
        };
        this.isSearching = true;

        this.resetStatsAndErrors();

        try {
            const locationIds = this.searched.locations.map(location => location.locationId);
            const locations = await this.dataUtils.chunkedAsyncMap(
                MAX_CONCURRENT_REQUESTS,
                locationIds,
                async locationId => {
                    this.loading.all++;
                    const location = await this.mvLocation.getAssignedLocation(locationId);
                    this.progress.all++;
                    this.loading.all--;
                    return location;
                },
            );
            const locationGraphqlIds = pluck('graphqlId', locations);

            this.isLoadingAppsForLocation = true;
            const graphqlClient = this.graphqlService.getClient();
            const chunkedLocationGraphqlIds = chunk(5, locationGraphqlIds);
            const nestedLocationApps: LocationApp[][] = await chunkedAsyncMap(
                MAX_CONCURRENT_REQUESTS,
                chunkedLocationGraphqlIds,
                async ids => {
                    this.loading.all++;
                    const result = await graphqlClient.query<GetAppsForLocations>({
                        fetchPolicy: 'network-only',
                        query: GET_APPS_FOR_LOCATIONS_QUERY,
                        variables: { locationIds: ids },
                    });
                    this.progress.all++;
                    this.loading.all--;
                    return relayConnectionToArray(result.data.locations);
                },
            );
            const locationApps: LocationApp[] = flatten(nestedLocationApps);
            this.isLoadingAppsForLocation = false;

            for (const processor of this.reportingChannelProcessors) {
                // eslint-disable-next-line no-await-in-loop
                await processor(this.queryCount, locations, locationApps);
            }

            if (CONVERSIONS_TABLE_ENABLED) {
                // eslint-disable-next-line consistent-return
                return await this.fetchConversionStats(locations, locationApps);
            }
        } finally {
            this.isSearching = false;
        }
    }

    public changeLocation(location: AssignedLocation) {
        this.searchCriteria.locations = [assignedLocationToLocationIdHierarchy(location)];
        this.resetReport();
    }

    public resetReport(): void {
        this.resetReportTablesExpandedLocations();
        this.resetStatsAndErrors();
        this.searched = null;
        this.isSearching = false;
        this.queryCount = 0;
    }

    public chooseAdditionalLocations(): IPromise<any> {
        const newScope: any = this.$scope.$new();
        newScope.preselectedLocations = this.searchCriteria.locations;
        const modalInstance = this.$modal.open({
            controller: 'mvAdditionalLocationsCtrl',
            scope: newScope,
            templateUrl: '/partials/reports/additionalLocations',
        });

        return modalInstance.result.then((additionalLocations: LocationIdHierarchy[]) => {
            this.searchCriteria.locations = additionalLocations;
        });
    }

    public getReportTableCurrencies(reportTable: ReportTable<StatsEntry<HasCurrencyCode>, HasCurrencyCode>) {
        const entries = reportTable.data.getEntries();
        const currencyCodes = linq
            .from(entries)
            .select(entry => entry.currencyCode)
            .distinct()
            .toArray();
        return currencyCodes;
    }

    public isSingleCurrencyFacebookMarketing(reportTable: ReportTable<StatsEntry<HasCurrencyCode>, HasCurrencyCode>) {
        const currencies = this.getReportTableCurrencies(reportTable);
        return currencies.length === 1 && !isNullOrUndefined(currencies[0]);
    }

    public filterNonZeroCount(obj: { count: number }) {
        return this.nonZeroPredicate(obj.count);
    }

    public openDatePicker($event: ng.IAngularEvent, datePickerName: 'endDate' | 'startDate') {
        $event.preventDefault();
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
        this.datePickerIsOpen[datePickerName] = true;
    }

    public editReportSummary(reportSummary: ReportSummary) {
        this.$location.path(`/reports/summaries/${reportSummary.id}/edit`);
    }

    public getSearchedLocationsText(): string | undefined {
        if (this.searched) {
            const origLocs = this.searched.locations;
            let locs = this.orderBy(origLocs, ['clientTitle', 'locationTitle']);
            locs = locs.slice(0, this.LIMIT_LOCATIONS_TO_DISPLAY);
            const newLocs = [];
            for (const loc of locs) {
                // NewLocs.push(loc.client + ' - ' + loc.title);
                newLocs.push(loc.locationTitle);
            }
            let locsString = newLocs.join(', ');
            if (origLocs.length > locs.length) {
                const diff = origLocs.length - locs.length;
                locsString += `, and ${diff} other location`; // TODO: translate this
                if (diff > 1) {
                    locsString += 's'; // TODO: translate this
                }
            }
            return locsString;
        }
        return undefined;
    }

    protected pushErrorMessage(msg: string): void {
        if (this.errors.indexOf(msg) === -1) {
            this.errors.push(msg);
        }
    }

    private async updateCurrentLocation(currentLocationId: LocationId) {
        if (!this.locations) {
            try {
                this.locations = await this.mvLocation.getAssignedLocations();
            } catch (error) {
                this.mvNotifier.unexpectedError(error);
            }
        }

        if (!this.locations) {
            return;
        }

        let currentLocation = null;
        if (this.locations.length === 1) {
            currentLocation = this.locations[0];
        } else {
            currentLocation = this.locations.find(location => location.locationId === currentLocationId);
        }

        if (!currentLocation) {
            return;
        }

        this.searchCriteria.locations = [currentLocation];
        this.currentLocation = currentLocation;
        this.resetReport();
    }

    private getReportPeriod(): number {
        return moment(this.searchCriteria.endDate).diff(moment(this.searchCriteria.startDate), 'days') + 1;
    }

    private resetReportTablesExpandedLocations() {
        const arr = [];
        if (this.searchCriteria.locations && this.searchCriteria.locations.length === 1) {
            arr.push(this.searchCriteria.locations[0].locationId);
        }
        this.expandedLocationIds = {
            fitwareMemberEngager: arr.slice(),
            googleAdWords: arr.slice(),
        };
    }

    private setChartDataHack(settingFunction: () => void) {
        // See https://github.com/jtblin/angular-chart.js/issues/29
        return this.$timeout(settingFunction, 100);
    }

    private async doStatsForLocation<T>(
        searchCriteria: SingleSearchCriteria,
        currentQueryId: number,
        statsService: ILocationStatsService<T, unknown>,
        // eslint-disable-next-line camelcase
        appsEnabledWithToken: GetAppsForLocations_locations_edges_node | undefined,
    ): Promise<T | null> {
        const clientPlatforms = appsEnabledWithToken?.client
            ? relayConnectionToArray(appsEnabledWithToken.client.platforms)
            : [];
        const locationApps = appsEnabledWithToken?.apps ? relayConnectionToArray(appsEnabledWithToken.apps) : [];

        if (!statsService.isPlatformEnabled(searchCriteria.location, clientPlatforms)) {
            return null;
        } else if (!statsService.isPlatformConfigured(searchCriteria.location, locationApps)) {
            const msg = stripIndents`
                ${searchCriteria.location.title}: ${statsService.displayName} - Configuration missing.
            `;
            this.pushErrorMessage(msg);
            return null;
        } else {
            try {
                const res = await statsService.getStatsForLocation(searchCriteria);
                return currentQueryId === this.queryCount ? res.data || null : null;
            } catch (error) {
                this.handleErrorGracefully(searchCriteria.location.title, statsService.displayName, error);
                return null;
            }
        }
    }


    private doStats<TEntry extends object, TMappedEntry>(
        currentQueryId: number,
        statsService: ILocationStatsService<TEntry, TMappedEntry>,
        mapper: (entries: Array<StatsEntry<TEntry>>) => Array<StatsEntry<TMappedEntry>>,
        locations: AssignedLocation[],
        locationApps: LocationApp[],
    ): angular.IPromise<PlatformStats<TMappedEntry> | null> {
        const serviceName = statsService.serviceName;
        const { endDate, startDate } = this.searchCriteria;
        if (!startDate || !endDate || isNaN(endDate.getTime()) || isNaN(startDate.getTime())) {
            return Promise.resolve(null);
        }

        return this.dataUtils
            .chunkedAsyncMap(MAX_CONCURRENT_REQUESTS, locations, async location => {
                const singleSearchCriteria: SingleSearchCriteria = {
                    endDate,
                    location,
                    startDate,
                };
                const appsEnabledWithToken = locationApps.find(x => x.id === location.graphqlId);

                this.loading[serviceName]++;
                this.loading.all++;
                const stats = await this.doStatsForLocation<TEntry>(
                    singleSearchCriteria,
                    currentQueryId,
                    statsService,
                    appsEnabledWithToken,
                );
                this.loading[serviceName]--;
                this.loading.all--;

                this.progress[serviceName]++;
                this.progress.all++;

                if (!stats) {
                    return null;
                }

                // Stats.location isn't correctly typed. This is the workaround
                (stats as any).location = assignedLocationToLocationIdHierarchy(location);
                return stats;
            })
            .then<Array<StatsEntry<any>>>(this.filterStatsEntries.bind(this))
            .then(mapper)
            .then((stats: Array<StatsEntry<TMappedEntry>>): PlatformStats<TMappedEntry> | null => {
                if (stats.length > 0) {
                    return {
                        entries: stats,
                        totals: statsService.combineStats(stats),
                    };
                } else {
                    return null;
                }
            })
            .then((stats: PlatformStats<TMappedEntry> | null): PlatformStats<TMappedEntry> | null => {
                (this.stats as Untyped)[statsService.serviceName] = stats;
                const reportTable = (this.reportTables as Untyped)[statsService.serviceName];
                if (stats && reportTable instanceof ReportTable) {
                    reportTable.data.update(stats.entries, stats.totals, this.getReportPeriod());
                }
                return stats;
            });
    }

    private flattenAndInvertEntries<T extends Record<string, any>>(
        statsEntries: Array<StatsEntry<T[]>>,
    ): Array<StatsEntry<T>> {
        return this.dataUtils.flatten(
            statsEntries.map(
                (value: StatsEntry<T[]>, index: number, arr: Array<StatsEntry<T[]>>): Array<StatsEntry<T>> =>
                    this.invertEntry(value),
            ),
        );
    }

    private invertEntry<T extends Record<string, any>>(statsEntry: StatsEntry<T[]>): Array<StatsEntry<T>> {
        return statsEntry.map((value: T, index: number, arr: T[]): StatsEntry<T> => {
            const newEntry: StatsEntry<T> = {
                ...(value as any),
                location: statsEntry.location,
            };
            return newEntry;
        });
    }

    private filterStatsEntries(entries: Array<StatsEntry<any>>) {
        return entries.filter((value: StatsEntry<any>, index: number, array: Array<StatsEntry<any>>) => value !== null);
    }

    private handleErrorGracefully(locationTitle: string, displayName: string, err: any) {
        let errorHandled = false;
        if (err && err.data) {
            const errorData: ServerError = err.data;
            if ((errorData.code && this.gracefulErrorCodes.indexOf(errorData.code) > -1) || errorData.reason) {
                const msg = `${locationTitle}: ${displayName} - ${errorData.reason}`;
                this.pushErrorMessage(msg);
                errorHandled = true;
            }
        }
        if (!errorHandled) {
            this.mvNotifier.unexpectedErrorWithData(`Failed to retrieve ${displayName} stats`, err);
        }
    }

    private getCampaignMonitorStats(
        currentQueryId: number,
        locations: AssignedLocation[],
        locationApps: LocationApp[],
    ): angular.IPromise<void> {
        return this.doStats<CampaignMonitorStats, CampaignMonitorStatsEntry>(
            currentQueryId,
            this.campaignMonitorStatsService,
            values => this.flattenAndInvertEntries(values),
            locations,
            locationApps,
        ).then(noop);
    }

    private async getMailchimpStats(
        currentQueryId: number,
        locations: AssignedLocation[],
        locationApps: LocationApp[],
    ): Promise<void> {
        await this.doStats<MailchimpReportEntry[], MailchimpReportEntry>(
            currentQueryId,
            this.mailchimpStatsService,
            values => this.flattenAndInvertEntries(values),
            locations,
            locationApps,
        );
    }

    private async getSendGridStats(
        currentQueryId: number,
        locations: AssignedLocation[],
        locationApps: LocationApp[],
    ): Promise<void> {
        await this.doStats<SendgridEmailCampaignReport[], SendgridEmailCampaignReport>(
            currentQueryId,
            this.sendGridStatsService,
            values => this.flattenAndInvertEntries(values),
            locations,
            locationApps,
        );
    }

    private getClientMap(clientIds: number[]): ng.IPromise<{ [clientId: number]: ClientResource }> {
        return this.$q
            .all(clientIds.map(clientId => this.clientResource.get({ id: clientId }).$promise))
            .then((clients: ClientResource[]) => this.dataUtils.createMap(client => client.id, clients));
    }

    private warnAboutConversionsIfConversionPlatformsNotConfigured(locations: AssignedLocation[]) {
        for (const location of locations) {
            for (const service of this.conversionStatsServices) {
                if (!service.isPlatformConfigured(location)) {
                    this.pushErrorMessage(
                        this.i18n.text.report.conversionsPlatformNotConfiguredForLocation({
                            location: location.title,
                            platform: service.displayName,
                        }),
                    );
                }
            }
        }
    }

    private shouldFetchConversionStatsForLocation(location: AssignedLocation, locationApps: LocationApp[]): boolean {
        const appsEnabledWithToken = locationApps.find(x => x.legacyId === location.id);
        for (const service of this.conversionStatsServices) {
            const apps = appsEnabledWithToken?.apps ? relayConnectionToArray(appsEnabledWithToken.apps) : [];
            if (!service.isPlatformConfigured(location, apps)) {
                return false;
            }
        }
        return true;
    }

    private fetchConversionStats(locations: AssignedLocation[], locationApps: LocationApp[]): ng.IPromise<void> {
        const locationsToFetch = locations.filter(location =>
            this.shouldFetchConversionStatsForLocation(location, locationApps),
        );
        const clientIds = linq
            .from(locationsToFetch)
            .select(location => location.clientId)
            .distinct()
            .toArray();

        return this.getClientMap(clientIds).then(clientMap => {
            this.warnAboutConversionsIfConversionPlatformsNotConfigured(locations);

            const fitwareConversions = this.reportTables.fitwareConversions.data.getEntries().map(entry => {
                const client = clientMap[entry.location.clientId];
                return {
                    conversionCount: entry.TotalSale,
                    conversionCurrency: client.fitwareConversionCurrency,
                    conversionWorth: client.fitwareConversionAmountInCents / 100,
                    location: entry.location,
                    platformName: this.i18n.text.common.products.fitware(),
                    totalConversionWorth: (client.fitwareConversionAmountInCents / 100) * entry.TotalSale,
                };
            });

            const conversions: Array<StatsEntry<ConversionStats>> = (fitwareConversions || []).slice();

            const totals = this.getConversionTotals(conversions);

            this.reportTables.conversions.data.update(conversions, totals, this.getReportPeriod());
        });
    }

    private getUniqueOrNull<T>(values: T[]): T | null {
        // eslint-disable-next-line no-param-reassign
        values = linq.from(values).distinct().toArray();

        return values.length === 1 ? values[0] : null;
    }

    private getConversionTotals(entries: Array<StatsEntry<ConversionStats>>): ConversionStats {
        const conversionCount = linq.from(entries).sum(entry => entry.conversionCount);
        const conversionCurrency = this.getUniqueOrNull(entries.map(entry => entry.conversionCurrency));
        const conversionWorth = this.getUniqueOrNull(entries.map(entry => entry.conversionWorth));
        const totalConversionWorth =
            conversionCurrency !== null
                ? linq.from(entries).sum(entry => (entry.conversionWorth || 0) * entry.conversionCount)
                : null;

        const totals: ConversionStats = {
            conversionCount,
            conversionCurrency,
            conversionWorth,
            platformName: '',
            totalConversionWorth,
        };

        return totals;
    }

    private getFacebookStats(currentQueryId: number, locations: AssignedLocation[], locationApps: LocationApp[]) {
        return this.doStats(currentQueryId, this.facebookStatsService, passThrough, locations, locationApps)
            .then(this.drawFacebookChart.bind(this))
            .then(noop);
    }

    private getFacebookMarketingStats(
        currentQueryId: number,
        locations: AssignedLocation[],
        locationApps: LocationApp[],
    ) {
        return this.doStats(
            currentQueryId,
            this.facebookMarketingStatsService,
            (
                entries: Array<StatsEntry<FacebookMarketingStatsForLocation>>,
            ): Array<StatsEntry<SimpleFacebookAdSetStats>> =>
                this.dataUtils.flatten(
                    entries.map(
                        (
                            entry: StatsEntry<FacebookMarketingStatsForLocation>,
                        ): Array<StatsEntry<SimpleFacebookAdSetStats>> =>
                            entry.adSetInsights.map(
                                (adSetInsight: SimpleFacebookAdSetStats): StatsEntry<SimpleFacebookAdSetStats> => ({
                                    ...adSetInsight,
                                    location: entry.location,
                                }),
                            ),
                    ),
                ),
            locations,
            locationApps,
        ).then(noop);
    }

    private getFitwareMemberEngagerStats(
        currentQueryId: number,
        locations: AssignedLocation[],
        locationApps: LocationApp[],
    ) {
        return this.doStats<FitwareMemberEngagerStatsForLocation, GroupedFitwareMemberEngagerStats>(
            currentQueryId,
            this.fitwareMemberEngagerStatsService,
            (entries): Array<StatsEntry<GroupedFitwareMemberEngagerStats>> =>
                entries
                    .filter(locationData => locationData.batches.length > 0)
                    .map((locationData): StatsEntry<GroupedFitwareMemberEngagerStats> => {
                        const location = locationData.location;
                        const mappedBatches: Array<StatsEntry<BatchStatsEntry>> = locationData.batches.map(entry => ({
                            location,
                            ...entry,
                        }));
                        return {
                            entries: mappedBatches,
                            location,
                            totals: this.fitwareMemberEngagerStatsService.combineStatsForBatchEntries(mappedBatches),
                        };
                    }),
            locations,
            locationApps,
        ).then(noop);
    }

    private getGoogleStats(currentQueryId: number, locations: AssignedLocation[], locationApps: LocationApp[]) {
        return this.doStats(currentQueryId, this.googleStatsService, passThrough, locations, locationApps)
            .then(this.drawGoogleChart.bind(this))
            .then(noop);
    }

    private getGoogleAdWordsStats(currentQueryId: number, locations: AssignedLocation[], locationApps: LocationApp[]) {
        return this.doStats(currentQueryId, this.googleAdWordsStatsService, passThrough, locations, locationApps)
            .then(platformStats => {
                if (platformStats) {
                    const reportPeriod = this.getReportPeriod();
                    this.reportTables.googleAdWords.brandAwareness.byAd.data.update(
                        platformStats.entries.map(entry => ({
                            location: entry.location,
                            ...entry.brandAwareness.byAd,
                        })),
                        platformStats.totals.brandAwareness.byAd,
                        reportPeriod,
                    );

                    this.reportTables.googleAdWords.brandAwareness.byKeyword.data.update(
                        platformStats.entries.map(entry => ({
                            location: entry.location,
                            ...entry.brandAwareness.byKeyword,
                        })),
                        platformStats.totals.brandAwareness.byKeyword,
                        reportPeriod,
                    );

                    this.reportTables.googleAdWords.conversions.byAd.data.update(
                        platformStats.entries.map(entry => ({
                            location: entry.location,
                            ...entry.conversions.byAd,
                        })),
                        platformStats.totals.conversions.byAd,
                        reportPeriod,
                    );

                    this.reportTables.googleAdWords.conversions.byKeyword.data.update(
                        platformStats.entries.map(entry => ({
                            location: entry.location,
                            ...entry.conversions.byKeyword,
                        })),
                        platformStats.totals.conversions.byKeyword,
                        reportPeriod,
                    );
                }
                return platformStats;
            })
            .then(noop);
    }

    private getInstagramStats(currentQueryId: number, locations: AssignedLocation[], locationApps: LocationApp[]) {
        return this.doStats(currentQueryId, this.instagramStatsService, passThrough, locations, locationApps).then(
            noop,
        );
    }

    private getInternalStats(currentQueryId: number, locations: AssignedLocation[], locationApps: LocationApp[]) {
        return this.doStats(currentQueryId, this.internalStatsService, passThrough, locations, locationApps)
            .then(this.filterAndSortInternalStats.bind(this))
            .then(this.drawInternalCharts.bind(this))
            .then(noop);
    }

    private getTwitterStats(currentQueryId: number, locations: AssignedLocation[], locationApps: LocationApp[]) {
        return this.doStats(currentQueryId, this.twitterStatsService, passThrough, locations, locationApps).then(noop);
    }

    /* TODO: replace this with builtin function, or dataUtils */
    private filterArray(data: Untyped, fnPredicate: Untyped) {
        const output = [];
        for (const datum of data) {
            if (fnPredicate(datum)) {
                output.push(datum);
            }
        }
        return output;
    }

    private filterAndSortInternalStats(
        statsEntry: PlatformStats<InternalStats> | null,
    ): PlatformStats<InternalStats> | undefined {
        if (!statsEntry) {
            return undefined;
        }
        const data = statsEntry.totals;
        let activities = data.planners.activities;
        let channels = data.planners.channels;
        activities = this.orderBy(this.filterArray(activities, this.filterNonZeroCount.bind(this)), [
            '-count',
            'label',
        ]);
        channels = this.orderBy(this.filterArray(channels, this.filterNonZeroCount.bind(this)), ['-count', 'label']);
        data.planners.activities = activities;
        data.planners.channels = channels;

        return statsEntry;
    }

    private getReportSummariesForLocation(
        currentQueryId: number,
        location: LocationIdHierarchy,
    ): angular.IPromise<ReportSummary> {
        this.loading.reportSummaries++;
        this.progress.all++;
        this.progress.reportSummaries++;
        const resource = this.mvReportSummaryResource.query(
            {
                endDate: this.searchCriteria.endDate!.toISOString(),
                locationId: location.locationId,
                startDate: this.searchCriteria.startDate!.toISOString(),
            },
            (data: Untyped) => {
                let result = null;
                if (currentQueryId === this.queryCount) {
                    result = data;
                }
                this.loading.reportSummaries--;
                return result;
            },
            (res: Untyped): null => {
                this.mvNotifier.unexpectedErrorWithData(
                    `Failed to retrieve report summaries for ${location.locationTitle}`,
                    res,
                );
                this.loading.reportSummaries--;
                return null;
            },
        );
        return resource.$promise;
    }

    private getReportSummaries(currentQueryId: number, locations: AssignedLocation[]): IPromise<void> {
        // Silly, the code supports getting report summaries for multiple locations but guards against retrieving
        //  RSes if there's multiple locations.
        if (this.searchCriteria.locations.length === 1) {
            const promises = [];
            for (const location of this.searchCriteria.locations) {
                promises.push(this.getReportSummariesForLocation(currentQueryId, location));
            }

            return this.$q
                .all<ReportSummary[]>(promises)
                .then<ReportSummary[][]>(this.dataUtils.filterNulls)
                .then(this.dataUtils.flatten)
                .then((combinedSummaries: ReportSummary[]) => {
                    this.stats.reportSummaries = combinedSummaries;
                    return combinedSummaries;
                })
                .then(noop);
        } else {
            this.pushErrorMessage(this.MESSAGE_REPORT_SUMMARIES_HIDDEN);
            return this.$q.resolve();
        }
    }

    private async drawFacebookChart(statsEntry: PlatformStats<FacebookStatsForLocation> | null): Promise<void> {
        if (statsEntry) {
            const data = statsEntry.totals;
            await this.setChartDataHack(() => {
                this.charts.facebook = {
                    colours: this.chartColours,
                    data: [
                        data.reach.count - data.consumptions.count - data.positiveFeedback.count,
                        data.consumptions.count - data.positiveFeedback.count,
                        data.positiveFeedback.count,
                    ],
                    labels: ['Reach', 'Consumptions', 'Positive feedback'],
                };
            });
        }
    }

    private async drawGoogleChart(statsEntry: PlatformStats<GoogleStats> | null): Promise<void> {
        if (statsEntry) {
            const data = statsEntry.totals;
            await this.setChartDataHack(() => {
                this.charts.google = {
                    colours: [this.chartColours[0], this.chartColours[1]],
                    data: [data.users.returning, data.users.new],
                    labels: ['Returning visitors', 'New visitors'],
                };
            });
        }
    }

    private mapLabelsAndData<T>(
        fnGetLabel: (entry: T) => string,
        fnGetData: (entry: T) => any,
        entries: T[],
        fnFilter?: (value: any) => boolean,
    ) {
        const labels = [];
        const data = [];
        for (const entry of entries) {
            const label = fnGetLabel(entry);
            const datum = fnGetData(entry);
            if (!fnFilter || fnFilter(datum)) {
                labels.push(label);
                data.push(datum);
            }
        }
        return {
            data,
            labels,
        };
    }

    private nonZeroPredicate(value: number) {
        return value > 0;
    }

    private async drawInternalCharts(statsEntry: PlatformStats<InternalStats> | undefined): Promise<void> {
        if (statsEntry) {
            const data = statsEntry.totals;
            const activityData = this.mapLabelsAndData(
                entry => entry.label,
                entry => entry.count,
                data.planners.activities,
                this.nonZeroPredicate.bind(this),
            );
            const channelData = this.mapLabelsAndData(
                entry => `${entry.activityLabel} - ${entry.label}`,
                entry => entry.count,
                data.planners.channels,
                this.nonZeroPredicate.bind(this),
            );

            await this.setChartDataHack(() => {
                this.charts.internal = {
                    activities: {
                        colours: this.chartColours,
                        data: activityData.data,
                        labels: activityData.labels,
                    },
                    channels: {
                        colours: this.chartColours,
                        data: channelData.data,
                        labels: channelData.labels,
                    },
                    support: {
                        colours: this.chartColours,
                        data: [
                            data.planners.selfService,
                            data.planners.supported,
                            data.planners.general,
                            data.specialRequests.count,
                        ],
                        labels: ['Self-service events', 'Supported events', 'General events', 'Special requests'],
                    },
                };
            });
        }
    }

    private initialiseCountPerChannel(): CountPerChannel {
        return {
            all: 0,
            campaignMonitor: 0,
            clubReady: 0,
            facebook: 0,
            facebookMarketing: 0,
            fitware: 0,
            fitwareMemberEngager: 0,
            google: 0,
            googleAdWords: 0,
            instagram: 0,
            internal: 0,
            mailchimp: 0,
            reportSummaries: 0,
            sendgrid: 0,
            twitter: 0,
        };
    }

    private resetStatsAndErrors() {
        this.errors.length = 0;
        this.showErrors = false;
        this.stats = {
            campaignMonitor: undefined,
            facebook: undefined,
            facebookMarketing: undefined,
            fitware: undefined,
            google: undefined,
            googleAdWords: undefined,
            instagram: undefined,
            internal: undefined,
            reportSummaries: undefined,
            sendgrid: undefined,
            twitter: undefined,
        };
        this.charts = {
            facebook: null,
            google: null,
            internal: null,
        };
        this.loading = this.initialiseCountPerChannel();
        this.progress = this.initialiseCountPerChannel();
    }

    // eslint-disable-next-line max-statements
    private initialiseReportTables() {
        const reportOptions: CreateReportTableOptions = {
            formatters: new Formatters(this.$filter),
            i18n: this.i18n,
        };

        const getIsLocationExpanded = (serviceName: 'fitwareMemberEngager' | 'googleAdWords', locationId: LocationId) =>
            this.expandedLocationIds[serviceName].indexOf(locationId) > -1;
        const setIsLocationExpanded = (
            serviceName: 'fitwareMemberEngager' | 'googleAdWords',
            locationId: LocationId,
            value: boolean,
        ) => {
            const arr = this.expandedLocationIds[serviceName];
            const index = arr.indexOf(locationId);
            if (value) {
                if (index === -1) {
                    arr.push(locationId);
                }
            } else if (index > -1) {
                arr.splice(index, 1);
            }
        };

        const campaignMonitor = createCampaignMonitorReportTable(reportOptions);

        const facebook = createFacebookReportTable(reportOptions);

        const facebookMarketing = createFacebookMarketingReportTable(reportOptions);

        // TODO: group fitware
        const fitwareLeads = createFitwareLeadsReportTable(reportOptions);

        const fitwareSales = createFitwareSalesReportTable(reportOptions);

        const fitwareConversions = createFitwareConversionsReportTable(reportOptions);

        const getIsFitwareMemberEngagerLocationExpanded = (locationId: LocationId) =>
            getIsLocationExpanded('fitwareMemberEngager', locationId);
        const setIsFitwareMemberEngagerLocationExpanded = (locationId: LocationId, value: boolean) =>
            setIsLocationExpanded('fitwareMemberEngager', locationId, value);
        const fitwareMemberEngager = createFitwareMemberEngagerReportTable({
            ...reportOptions,
            getIsLocationExpanded: getIsFitwareMemberEngagerLocationExpanded,
            setIsLocationExpanded: setIsFitwareMemberEngagerLocationExpanded,
        });

        const google = createGoogleReportTable(reportOptions);

        const getIsGoogleAdWordsLocationExpanded = (locationId: LocationId) =>
            getIsLocationExpanded('googleAdWords', locationId);
        const setIsGoogleAdWordsLocationExpanded = (locationId: LocationId, value: boolean) =>
            setIsLocationExpanded('googleAdWords', locationId, value);
        const googleAdWords = {
            brandAwareness: {
                byAd: createGoogleAdWordsBrandAwarenessReportTable({
                    ...reportOptions,
                    getIsLocationExpanded: getIsGoogleAdWordsLocationExpanded,
                    reportByType: 'ad',
                    setIsLocationExpanded: setIsGoogleAdWordsLocationExpanded,
                }),
                byKeyword: createGoogleAdWordsBrandAwarenessReportTable({
                    ...reportOptions,
                    getIsLocationExpanded: getIsGoogleAdWordsLocationExpanded,
                    reportByType: 'keyword',
                    setIsLocationExpanded: setIsGoogleAdWordsLocationExpanded,
                }),
            },
            conversions: {
                byAd: createGoogleAdWordsConversionsReportTable({
                    ...reportOptions,
                    getIsLocationExpanded: getIsGoogleAdWordsLocationExpanded,
                    reportByType: 'ad',
                    setIsLocationExpanded: setIsGoogleAdWordsLocationExpanded,
                }),
                byKeyword: createGoogleAdWordsConversionsReportTable({
                    ...reportOptions,
                    getIsLocationExpanded: getIsGoogleAdWordsLocationExpanded,
                    reportByType: 'keyword',
                    setIsLocationExpanded: setIsGoogleAdWordsLocationExpanded,
                }),
            },
        };

        const instagram = createInstagramReportTable(reportOptions);

        const internal = createInternalReportTable(reportOptions);

        const mailchimp = createMailchimpReportTable(reportOptions);

        const sendgrid = createSendGridReportTable(reportOptions);

        const twitter = createTwitterReportTable(reportOptions);

        const conversions = createConversionSummaryReportTable(reportOptions);

        this.reportTables = Object.freeze({
            campaignMonitor,
            conversions,
            facebook,
            facebookMarketing,
            fitwareConversions,
            fitwareLeads,
            fitwareMemberEngager,
            fitwareSales,
            google,
            // TODO: There's a typing mismatch with this type.
            googleAdWords: googleAdWords as any,
            instagram,
            internal,
            mailchimp,
            sendgrid,
            twitter,
        });
    }
}

// Angular.module('app').controller(ReportPageController.SID, ReportPageController);
