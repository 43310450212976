import { ClientId, AssignedLocation, ReportableService } from '@deltasierra/shared';
import { $httpSID } from '../../common/angularData';
import { MvLocation } from '../../locations/mvLocation';
import { BaseLocationStatsService, CanConfigureResponse } from './common';
import IHttpService = angular.IHttpService;

export interface GoogleStats {
    users: {
        count: number;
        new: number;
        returning: number;
    };
}

export class GoogleStatsService extends BaseLocationStatsService<GoogleStats, GoogleStats> {
    public static readonly SID = 'googleStatsService';

    public readonly serviceName: ReportableService = 'google';

    public readonly displayName: string = 'Google';

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [$httpSID, MvLocation.SID];

    public constructor($http: IHttpService, protected readonly mvLocation: MvLocation) {
        super($http);
    }

    public canConfigureClient(clientId: ClientId): angular.IPromise<boolean> {
        return this.$http
            .get<CanConfigureResponse>(`/api/auth/${this.serviceName}/client/${clientId}/canConfigure`)
            .then((res: ng.IHttpPromiseCallbackArg<CanConfigureResponse>) => res.data!.canConfigure);
    }

    public isPlatformConfigured(location: AssignedLocation): boolean {
        return location.googleAnalyticsPagePath !== null;
    }

    public combineStats(stats: GoogleStats[]): GoogleStats {
        const initial: GoogleStats = {
            users: {
                count: 0,
                new: 0,
                returning: 0,
            },
        };
        return stats.reduce(this.reduceStats.bind(this), initial);
    }

    private reduceStats(
        previousValue: GoogleStats,
        currentValue: GoogleStats,
        currentIndex: number,
        array: GoogleStats[],
    ): GoogleStats {
        return {
            users: {
                count: previousValue.users.count + currentValue.users.count,
                new: previousValue.users.new + currentValue.users.new,
                returning: previousValue.users.returning + currentValue.users.returning,
            },
        };
    }
}

angular.module('app').service(GoogleStatsService.SID, GoogleStatsService);
