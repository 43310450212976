import fastClone from 'fast-clone';
import jsondiffpatch from 'jsondiffpatch';

export interface ChangeItem {
    description: string;
    delta: jsondiffpatch.Delta;
}

export class ObjectHistory<T> {
    private history: ChangeItem[] = [];

    private currentObject: T | null = null;

    private currentHistoryPosition = -1;

    constructor(private diffPatcher: jsondiffpatch.DiffPatcher, startingObject: T) {
        this.currentObject = fastClone(startingObject);
    }

    reset(object: T) {
        this.currentObject = fastClone(object);
        this.history = [];
        this.currentHistoryPosition = -1;
    }

    captureChange(changedObject: T, description = 'Change'): boolean {
        const changeDelta = this.diffPatcher.diff(this.currentObject, changedObject);
        if (changeDelta) {
            this.history.length = this.currentHistoryPosition + 1;
            this.history.push({ delta: changeDelta, description });
            this.currentObject = fastClone(changedObject);
            this.currentHistoryPosition++;
            return true;
        }

        // Nothing changed
        return false;
    }

    private getPrevChange(): ChangeItem | undefined {
        const hasHistory = this.currentHistoryPosition >= 0;
        const change = hasHistory ? this.history[this.currentHistoryPosition] : undefined;

        return change;
    }

    canUndo(): boolean {
        return !!this.getPrevChange();
    }

    undo(object?: T): T | null {
        const change = this.getPrevChange();
        const revertingObject = object || this.currentObject;
        if (change) {
            const changedObject = this.diffPatcher.unpatch(revertingObject, change.delta);
            this.currentHistoryPosition--;
            this.currentObject = fastClone(changedObject);
            return changedObject;
        }

        return revertingObject;
    }

    private getNextChange(): ChangeItem | undefined {
        const hasHistory = (this.currentHistoryPosition + 1) <= (this.history.length - 1);
        const change = hasHistory ? this.history[this.currentHistoryPosition + 1] : undefined;

        return change;
    }

    canRedo(): boolean {
        return !!this.getNextChange();
    }

    redo(object?: T): T | null {
        const change = this.getNextChange();
        const revertingObject = object || this.currentObject;
        if (change) {
            const changedObject = this.diffPatcher.patch(revertingObject, change.delta);
            this.currentHistoryPosition++;
            this.currentObject = fastClone(changedObject);
            return changedObject;
        }

        return revertingObject;
    }
}
