export class ExternalFrontendRouter<Routes extends string = string> {
    private readonly baseUrlWithoutSlash: string;

    public constructor(baseUrl: string) {
        this.baseUrlWithoutSlash = baseUrl.endsWith('/') ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl;
    }

    public get(subPath: Routes): string {
        return `${this.baseUrlWithoutSlash}${subPath}`;
    }
}
