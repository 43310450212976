/* eslint-disable max-lines-per-function */
import { gql } from '@apollo/client';
import {
    DSWellHeader,
    DSWellHeaderRightContent,
    DSWellHeaderTitle,
    LightboxMediaType,
    LightboxModal,
} from '@deltasierra/components';
import { getThumbnailFilePathUsingFileName } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { relayConnectionToArray } from '../../../graphql/utils';
import { AssetTableView } from '../AssetTableView';
import { AssetThumbnailView } from '../AssetThumbnailView';
import { AssetPermission, FileType, SortAssetsInput } from '../../../../../__graphqlTypes/globalTypes';
import { AssetOperationsDropdown } from '../AssetOperationsDropdown';
import { NoAssetsMessage } from '../NoAssetsMessage';
import { useAssetLibrarySettings } from '../../contexts';
import { ThumbnailViewToggle } from './ThumbnailViewToggle';
import { BackButton, Spacer, UpdateButtons } from './UpdateButtons';
import { CollectionAssetLightboxComponent } from './CollectionAssetLightboxComponent';
import {
    AssetConnectionForAssetList,
    AssetConnectionForAssetList_edges_node as AssetNode,
    AssetConnectionForAssetList_edges_node_AssetFile as AssetFile,
} from './__graphqlTypes/AssetConnectionForAssetList';
import { CollectionForAssetList } from './__graphqlTypes/CollectionForAssetList';
import { FolderForAssetList } from './__graphqlTypes/FolderForAssetList';

const fragments = {
    ASSET_CONNECTION_FOR_ASSET_LIST: gql`
        fragment AssetConnectionForAssetList on AssetConnection {
            edges {
                node {
                    __typename
                    ... on AssetFolder {
                        id
                        title
                    }
                    ... on AssetFile {
                        id
                        title
                        fileName
                        type
                    }
                    ... on AssetFileOrFolder {
                        ...AssetOperationsDropdownFragment
                    }
                    ...AssetThumbnailViewFragment
                    ...AssetTableViewFragment
                }
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
        ${AssetThumbnailView.fragments.AssetThumbnailViewFragment}
        ${AssetTableView.fragments.AssetTableViewFragment}
        ${AssetOperationsDropdown.fragments.AssetOperationsDropdownFragment}
    `,
    COLLECTION_FOR_ASSET_LIST: gql`
        fragment CollectionForAssetList on Collection {
            id
            assetCount
            title
            permissions
            ...AssetOperationsDropdownCollectionFragment
        }
        ${AssetOperationsDropdown.fragments.AssetOperationsDropdownCollectionFragment}
    `,
    FOLDER_FOR_ASSET_LIST: gql`
        fragment FolderForAssetList on AssetFolder {
            id
            title
            collection {
                id
                permissions
            }
            parent {
                id
            }
            ...AssetOperationsDropdownAssetFolderFragment
        }
        ${AssetOperationsDropdown.fragments.AssetOperationsDropdownAssetFolderFragment}
    `,
};

export type ViewOption = 'list' | 'thumbnail';

export interface CollectionAssetsWithDataProps {
    assetConnection: AssetConnectionForAssetList;
    currentAsset: CollectionForAssetList | FolderForAssetList;
    enableLightboxPreview: boolean;
    setSort: (sort: SortAssetsInput | null) => void;
    setViewOption: (option: ViewOption) => void;
    sort: SortAssetsInput | null;
    viewOption: ViewOption;
}

function isAssetFile(asset: AssetNode): asset is AssetFile {
    return asset.__typename === 'AssetFile';
}

/**
 * CollectionAssetsWithData
 * The functional partner to `CollectionAssets`
 * It displays a few actions, dropdowns, tools, and then renders either a table or thumbmnail view of the assets
 *
 * @param props - CollectionAssetsWithDataProps
 * @param props.currentAsset - the id of the current parent folder or collection
 * @param props.assetConnection - the connection of assets that are to be displayed, use provided fragment
 * @param props.enableLightboxPreview - Should users be allowed to view files in a lightbox modal
 * @param props.setSort - State setter function
 * @param props.viewOption - View option state
 * @param props.setViewOption - Sets view option
 * @param props.sort - State option
 * @returns CollectionAssetsWithData
 */
export function CollectionAssetsWithData({
    assetConnection,
    currentAsset,
    enableLightboxPreview,
    setSort,
    setViewOption,
    sort,
    viewOption,
}: CollectionAssetsWithDataProps): JSX.Element {
    const [isOpen, setIsOpen] = React.useState(false);
    const { hideOperations, mediaFilter } = useAssetLibrarySettings();
    const collectionPermissions =
        currentAsset.__typename === 'AssetFolder' ? currentAsset.collection.permissions : currentAsset.permissions;
    const collectionId = currentAsset.__typename === 'AssetFolder' ? currentAsset.collection.id : currentAsset.id;
    const backId =
        currentAsset.__typename === 'AssetFolder' ? currentAsset.parent?.id ?? currentAsset.collection.id : null;
    const assets = relayConnectionToArray(assetConnection).filter(asset => matchesMediaFilter(asset, mediaFilter));

    const getImageUrl = React.useCallback((asset: AssetFile) => asset.thumbnails?.large.url ?? asset.url, []);

    const initialAssets = React.useMemo(
        () =>
            assets.filter(isAssetFile).map(asset => {
                switch (asset.type) {
                    case FileType.IMAGE:
                        return {
                            key: asset.id,
                            title: asset.title,
                            type: LightboxMediaType.Image,
                            url: getImageUrl(asset),
                        };
                    case FileType.VIDEO:
                        return {
                            key: asset.id,
                            title: asset.title,
                            type: LightboxMediaType.Video,
                            url: asset.url,
                        };
                    case FileType.DOCUMENT:
                        return {
                            key: asset.id,
                            title: asset.title,
                            type: LightboxMediaType.Document,
                            url: asset.url,
                        };
                    default:
                        return {
                            key: asset.id,
                            title: asset.title,
                            type: LightboxMediaType.Unsupported,
                            url: getThumbnailFilePathUsingFileName(asset.title),
                        };
                }
            }),
        [assets, getImageUrl],
    );

    return (
        <>
            <LightboxModal.Provider elements={initialAssets}>
                <DSWellHeader>
                    <DSWellHeaderTitle data-cy="current-asset-title-header" noWrap>
                        {currentAsset.title}
                    </DSWellHeaderTitle>
                    {collectionPermissions.includes(AssetPermission.UPDATE) && !hideOperations && (
                        <UpdateButtons
                            backId={backId}
                            collectionId={collectionId}
                            currentAssetId={currentAsset.id}
                            currentAssetTitle={currentAsset.title}
                        />
                    )}
                    {backId && (
                        <Spacer>
                            <BackButton backId={backId} />
                        </Spacer>
                    )}
                    {!hideOperations && (
                        <DSWellHeaderRightContent>
                            <AssetOperationsDropdown
                                assets={relayConnectionToArray(assetConnection)}
                                currentAsset={currentAsset}
                            />
                        </DSWellHeaderRightContent>
                    )}
                </DSWellHeader>
                <RightFlex>
                    <ThumbnailViewToggle value={viewOption} onChange={setViewOption} />
                </RightFlex>
                {assetConnection.edges.length === 0 && <NoAssetsMessage />}
                {assetConnection.edges.length > 0 && viewOption === 'list' && (
                    <AssetTableView assets={assets} setSort={setSort} sort={sort} onPreview={() => setIsOpen(true)} />
                )}
                {assetConnection.edges.length > 0 && viewOption === 'thumbnail' && (
                    <AssetThumbnailView
                        assets={assets}
                        setSort={setSort}
                        sort={sort}
                        onPreview={() => setIsOpen(true)}
                    />
                )}
                {enableLightboxPreview && <CollectionAssetLightboxComponent isOpen={isOpen} setIsOpen={setIsOpen} />}
            </LightboxModal.Provider>
        </>
    );
}
CollectionAssetsWithData.displayName = 'CollectionAssetsWithData';
CollectionAssetsWithData.fragments = fragments;

const RightFlex = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

function matchesMediaFilter<T extends { __typename: string; type?: FileType }>(
    asset: T,
    mediaFilter?: ReadonlyArray<'document' | 'image' | 'video'>,
): boolean {
    return (
        asset.__typename === 'AssetFolder' ||
        mediaFilter === undefined ||
        (asset.type === FileType.DOCUMENT && mediaFilter.indexOf('document') > -1) ||
        (asset.type === FileType.IMAGE && mediaFilter.indexOf('image') > -1) ||
        (asset.type === FileType.VIDEO && mediaFilter.indexOf('video') > -1)
    );
}
