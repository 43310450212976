import { gql } from '@apollo/client';
import {
    DSClickAwayListener,
    DSGrow,
    DSIconButton,
    DSMenuItem,
    DSMenuList,
    DSPaper,
    DSPopper,
    HelpPopper,
    Translate,
} from '@deltasierra/components';
import { usePopover, useToggle } from '@deltasierra/react-hooks';
import { assertNever, PublishableService, t } from '@deltasierra/shared';
import MoreVert from '@material-ui/icons/MoreVert';
import * as React from 'react';
import { PostArtifactState } from '../../../../../__graphqlTypes/globalTypes';
import { useIsClubReadyPublishingDisabled } from '../../hooks/use-disable-club-ready-publishing';
import {
    canDeletePublishedPost,
    canEditCaption as canEditCaptionFn,
    canReschedule as canReschedulePost,
} from '../utils';
import { DeleteScheduledPostModal } from './DeleteScheduledPostModal';
import { EditCaptionModal } from './EditCaptionModal';
import { ReschedulePostModal } from './ReschedulePostModal';
import { ScheduledContextMenuFragment } from './__graphqlTypes/ScheduledContextMenuFragment';

const fragments = {
    ScheduledContextMenuFragment: gql`
        fragment ScheduledContextMenuFragment on PostArtifact {
            id
            meta {
                ... on InstagramPostArtifactMeta {
                    isStory
                }
            }
            platform {
                id
                name
            }
            status {
                state
            }
        }
    `,
};

export type ScheduledContextMenuProps = {
    post: ScheduledContextMenuFragment;
};

// eslint-disable-next-line max-lines-per-function
export function ScheduledContextMenu(props: ScheduledContextMenuProps): JSX.Element {
    const [element, isOpen, { handleClose, handleListKeyDown, handleToggle }] = usePopover();
    const [isRescheduleDialogOpen, toggleRescheduleDialog] = useToggle();
    const [isDeleteDialogOpen, toggleDeleteDialog] = useToggle();
    const [isEditDialogOpen, toggleEditDialog] = useToggle();

    const isClubReadyDisabled = useIsClubReadyPublishingDisabled();

    const isPlatformDisabled = isClubReadyDisabled && props.post.platform.name === 'clubReady';

    const canDelete =
        !isPlatformDisabled &&
        (props.post.status.state === PostArtifactState.SCHEDULED ||
            (canDeletePublishedPost(props.post.platform.name as PublishableService) &&
                props.post.status.state === PostArtifactState.POSTED));

    const canReschedule =
        !isPlatformDisabled &&
        canReschedulePost(props.post.platform.name as PublishableService) &&
        props.post.status.state === PostArtifactState.SCHEDULED;

        const isInstagramStoryPost =  props.post.meta &&
            props.post.meta.__typename === 'InstagramPostArtifactMeta' &&
            props.post.meta.isStory;

        const canEditCaption =
            !isPlatformDisabled &&
            canEditCaptionFn(props.post.platform.name as PublishableService) &&
            props.post.status.state === PostArtifactState.SCHEDULED &&
            // Can't edit captions for Instagram Story posts
            !isInstagramStoryPost;

    const handleMenuClick = (type: 'delete' | 'edit' | 'reschedule') => (event: React.MouseEvent<HTMLLIElement>) => {
        switch (type) {
            case 'delete':
                toggleDeleteDialog('toggleOn');
                break;
            case 'edit':
                toggleEditDialog('toggleOn');
                break;
            case 'reschedule':
                toggleRescheduleDialog('toggleOn');
                break;
            default:
                assertNever(type);
        }
        handleClose(event);
    };

    return (
        <>
            <DSIconButton
                aria-controls={isOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                aria-label={t('COMMON.ACTIONS')}
                color="primary"
                disabled={!canReschedule && !canDelete && !canEditCaption}
                size="small"
                onClick={handleToggle}
            >
                <MoreVert color="primary" />
            </DSIconButton>
            {isClubReadyDisabled && props.post.platform.name === 'clubReady' && (
                <HelpPopper margin="left" popperIcon="none">
                    <Translate keyId="BUILD.PUBLISH.CLUB_READY.DISABLED_PUBLISH_HELP_TEXT" />
                </HelpPopper>
            )}
            <DSPopper
                anchorEl={element}
                disablePortal
                open={isOpen}
                placement="bottom-end"
                style={{ zIndex: 1 }}
                transition
            >
                {({ placement, TransitionProps }) => (
                    <DSGrow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <DSPaper>
                            <DSClickAwayListener onClickAway={handleClose}>
                                <DSMenuList autoFocusItem={isOpen} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {canReschedule && (
                                        <DSMenuItem onClick={handleMenuClick('reschedule')}>
                                            <Translate keyId="SCHEDULE.PAGE.RESCHEDULE" />
                                        </DSMenuItem>
                                    )}
                                    {canEditCaption && (
                                        <DSMenuItem onClick={handleMenuClick('edit')}>
                                            <Translate keyId="COMMON.EDIT" />
                                        </DSMenuItem>
                                    )}
                                    {canDelete && (
                                        <DSMenuItem onClick={handleMenuClick('delete')}>
                                            <Translate keyId="COMMON.DELETE" />
                                        </DSMenuItem>
                                    )}
                                </DSMenuList>
                            </DSClickAwayListener>
                        </DSPaper>
                    </DSGrow>
                )}
            </DSPopper>
            <ReschedulePostModal
                isOpen={isRescheduleDialogOpen}
                postId={props.post.id}
                onCancel={() => toggleRescheduleDialog('toggleOff')}
                onConfirm={() => toggleRescheduleDialog('toggleOff')}
            />
            <DeleteScheduledPostModal
                isOpen={isDeleteDialogOpen}
                postId={props.post.id}
                onCancel={() => toggleDeleteDialog('toggleOff')}
                onConfirmDelete={() => toggleDeleteDialog('toggleOff')}
            />
            <EditCaptionModal
                isOpen={isEditDialogOpen}
                postId={props.post.id}
                onRequestCancel={() => toggleEditDialog('toggleOff')}
                onRequestConfirm={() => toggleEditDialog('toggleOff')}
            />
        </>
    );
}
ScheduledContextMenu.displayName = 'ScheduledContextMenu';
ScheduledContextMenu.fragments = fragments;
