import {
    AssignedLocation,
    ClientId,
    BuilderTemplateWithBasicAssociations,
    BuilderTemplateId,
    TemplateGroupItem,
    BuilderTemplateFormatId,
    ServiceName,
    BuilderTemplateCategoryId,
    BuilderDocumentFormat,
    ProtocolRelativeUrl,
} from '@deltasierra/shared';
import { LocationId, TagId } from '@deltasierra/ids';
import { convertIdToUniversalNodeId, parseAndValidateUniversalNodeId, relayConnectionToArray } from '../graphql/utils';
import { InteractionUtils } from '../common/interactionUtils';
import { SentryService } from '../common/sentryService';
import { BuilderTemplateApiClient } from '../contentBuilder/builderTemplateApiClient';
import { GraphqlService } from '../graphql/GraphqlService';
import { I18nService } from '../i18n';
import { IntroDataService } from '../intro/introDataService';
import { IntroService } from '../intro/introService';
import { MvIdentity } from '../account/mvIdentity';
import { BuilderTemplateFormatResource, MvBuilderTemplateFormatResource } from '../contentBuilder/mvBuilderTemplateFormatResource';
import { GetClientFeatures, GetClientFeaturesVariables } from '../common/clientFeatures/__graphqlTypes/GetClientFeatures';
import { GetUserFeatures } from '../common/userFeatures/__graphqlTypes/GetUserFeatures';
import { BuildableTemplateStatus, TagMatchType } from '../../../__graphqlTypes/globalTypes';
import { GET_CLIENT_FEATURES_QUERY } from '../common/clientFeatures/client-features.query';
import { GET_USER_FEATURES_QUERY } from '../common/userFeatures';
import { GET_ENABLED_INTEGRATIONS_FOR_CLIENT } from './GetEnabledIntegrationsForClient.query';
import { GET_GALLERY_TEMPLATE_DRAFTS_FOR_LOCATION_QUERY } from './GetGalleryTemplateDraftsForLocation.query';
import { GET_TEMPLATES_FOR_TEMPLATE_GROUP } from './GetTemplatesForTemplateGroup.query';
import { GET_BUILDABLE_TEMPLATES } from './GetBuildableTemplates.query';
import { GalleryQueryParams } from './mvBuilderTemplateGalleryCtrl';
import {
    GetGalleryTemplateDraftsForLocation,
    GetGalleryTemplateDraftsForLocationVariables,
} from './__graphqlTypes/GetGalleryTemplateDraftsForLocation';
import {
    GetTemplatesForTemplateGroup,
    GetTemplatesForTemplateGroup_builderTemplates as BuilderTemplate,
    GetTemplatesForTemplateGroupVariables,
} from './__graphqlTypes/GetTemplatesForTemplateGroup';
import { GetBuildableTemplates, GetBuildableTemplatesVariables, GetBuildableTemplates_client_buildableTemplates_edges_node } from './__graphqlTypes/GetBuildableTemplates';
import { GetEnabledIntegrationsForClient, GetEnabledIntegrationsForClientVariables } from './__graphqlTypes/GetEnabledIntegrationsForClient';
import IPromise = angular.IPromise;
import IQService = angular.IQService;

const TEMPLATE_GROUP_CONTAINER_CSS_SELECTOR = '.template-group--templates-container';
const TEMPLATE_GROUP_CSS_SELECTOR = '.template-group--templates';
const TEMPLATE_CSS_SELECTOR = '.template-group--templates--template';

export enum TemplateGroupTypeEnum {
    RecentlyAdded,
    Uncategorised,
    Category,
    SearchResults,
    Format,
}

export interface TemplateGroupTypeCategory {
    type: TemplateGroupTypeEnum.Category;
    categoryId: BuilderTemplateCategoryId;
}

export interface TemplateGroupTypeFormat {
    type: TemplateGroupTypeEnum.Format;
    formatId: number;
}

export type TemplateGroupType =
    | TemplateGroupTypeCategory
    | TemplateGroupTypeFormat
    | {
        type: TemplateGroupTypeEnum.RecentlyAdded;
    }
    | {
        type: TemplateGroupTypeEnum.SearchResults;
    }
    | {
        type: TemplateGroupTypeEnum.Uncategorised;
    };

export interface FetchTemplatesContext {
    queryParams: GalleryQueryParams;
}

export interface RefreshTemplatesContext {
    location?: AssignedLocation | null | undefined;
}

export function mapBuilderTemplateToTemplateGroupItem(
    builderTemplate: BuilderTemplate,
    clientId: ClientId,
): TemplateGroupItem {
    return {
        ...builderTemplate,
        buildableTemplateId: null,
        categories: builderTemplate.categories.map(category => ({
            id: BuilderTemplateCategoryId.from(category.id),
            title: category.title,
        })),
        clientId,
        compositeImage: builderTemplate.thumbnailUrl
            ? { thumb256x256url: ProtocolRelativeUrl.from(builderTemplate.thumbnailUrl) }
            : null,
        createdAt: new Date(builderTemplate.createdAt),
        currentRevisionId: null,
        documentFormat: builderTemplate.documentFormat as BuilderDocumentFormat,
        formats: relayConnectionToArray(builderTemplate.formatsConnection).map(format => ({
            ...format,
            id: BuilderTemplateFormatId.from(format.legacyId),
            platform: {
                label: format.platform.displayName,
                name: format.platform.name as ServiceName,
            },
            platformId: format.platform.legacyId,
        })),
        graphqlId: builderTemplate.id,
        id: BuilderTemplateId.from(builderTemplate.legacyId),
        tags: builderTemplate.tags.slice(),
    };
}

export function mapBuilderTemplateWithBasicAssociationsToTemplateGroupItem(
    builderTemplate: BuilderTemplateWithBasicAssociations,
): TemplateGroupItem {
    const hasImage =
        builderTemplate.compositeImage &&
        (builderTemplate.compositeImage.thumb256x256url || builderTemplate.compositeImage.url);
    return {
        ...builderTemplate,
        buildableTemplateId: null,
        compositeImage: hasImage
            ? {
                thumb256x256url: ProtocolRelativeUrl.from(
                    builderTemplate.compositeImage!.thumb256x256url ?? builderTemplate.compositeImage!.url ?? '',
                ),
            }
            : null,
        currentRevisionId: null,
    };
}

export class TemplateGroup {
    public allLoaded!: boolean;

    public lastLoadedTemplate?: Omit<TemplateGroupItem, 'hasRelatedDraft'>;

    public readonly templatesWithoutLocationInfo: Array<Omit<TemplateGroupItem, 'hasRelatedDraft'>>;

    public readonly templates: TemplateGroupItem[];

    public scrolledToLeftEdge = true;

    public scrolledToRightEdge = true;

    public showBuildableTemplates = false;

    public showDeprecatedBuilderTemplates = false;

    public readonly fetchTemplates = this.interactionUtils.createFuture<void, FetchTemplatesContext>(
        this.i18nService.text.common.fetchData(),
        async context => {
            if (this.allLoaded) {
                return;
            }

            const { clientFeatures, userFeatures } = await this.getClientAndUserFeatures(context.queryParams.client);

            const showBuildableTemplatesFeatureFlag =
                context.queryParams.client !== undefined &&
                clientFeatures.client?.features.templateGallery === true;

            const showBuildableAndBuilderTemplatesFeatureFlag =
                context.queryParams.client !== undefined &&
                userFeatures.me.features.showBuildableAndBuilderTemplates;

            // If both flags are disabled, then we only show builder templates
            this.showBuildableTemplates = !userFeatures.me.features.disableTemplateGalleryV2UserOverride && (
                showBuildableTemplatesFeatureFlag ||
                showBuildableAndBuilderTemplatesFeatureFlag
            );
            this.showDeprecatedBuilderTemplates = userFeatures.me.features.disableTemplateGalleryV2UserOverride ||
                !showBuildableTemplatesFeatureFlag ||
                showBuildableAndBuilderTemplatesFeatureFlag;

            const initialDtos = await this.getAllTemplatesViaGraphql(context.queryParams);

            this.templatesWithoutLocationInfo.push(...initialDtos);

            this.itemsRequested += context.queryParams.limit;

            await this.refreshLocalFilters();
        },
    );

    public readonly refreshTemplates = this.interactionUtils.createFuture<void, RefreshTemplatesContext>(
        this.i18nService.text.common.fetchData(),
        async context => {
            this.location = context.location;

            await this.refreshLocalFilters();
        },
    );

    private itemsRequested = 0;

    // eslint-disable-next-line max-params
    public constructor(
        protected readonly $q: IQService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly i18nService: I18nService,
        protected readonly builderTemplateApiClient: BuilderTemplateApiClient,
        protected readonly introService: IntroService,
        protected readonly introDataService: IntroDataService,
        private readonly graphqlService: GraphqlService,
        private readonly sentryService: SentryService,
        private readonly builderTemplateFormatResource: MvBuilderTemplateFormatResource,
        private mvIdentity: MvIdentity,

        public readonly groupLabel: string,
        public readonly groupType: TemplateGroupType,
        private location: AssignedLocation | null | undefined,
    ) {
        this.templates = [];
        this.templatesWithoutLocationInfo = [];
    }

    public $onInit(): void {
        this.reset();
    }

    public reset(): void {
        this.templates.length = 0;
        this.templatesWithoutLocationInfo.length = 0;
        this.itemsRequested = 0;
        this.allLoaded = false;
        this.lastLoadedTemplate = undefined;
        this.fetchTemplates.reset();
        this.refreshTemplates.reset();
    }

    public loadMore(queryParams: GalleryQueryParams): IPromise<void> {
        return this.fetchTemplates.run({
            queryParams: {
                ...this.getPage(),
                ...queryParams,
            },
        });
    }

    public scrolledToLeftEdgeCallback(scrolledToLeftEdge: boolean): void {
        this.scrolledToLeftEdge = scrolledToLeftEdge;
    }

    public scrolledToRightEdgeCallback(scrolledToRightEdge: boolean): void {
        this.scrolledToRightEdge = scrolledToRightEdge;
    }

    public scrollLeft($event: JQueryEventObject): void {
        const $element = this.findTemplateGroupElement($event);
        const template = $element.find(TEMPLATE_CSS_SELECTOR);
        $element
            .clearQueue()
            .stop()
            .animate(
                { scrollLeft: Math.max($element.scrollLeft() - $element.width() + template.width(), 0) },
                750,
                'easeOutCubic',
            );
    }

    public scrollRight($event: JQueryEventObject): void {
        const $element = this.findTemplateGroupElement($event);
        const template = $element.find(TEMPLATE_CSS_SELECTOR);
        const domElement = $element.get(0);
        $element
            .clearQueue()
            .stop()
            .animate(
                {
                    scrollLeft: Math.min(
                        $element.scrollLeft() + $element.width() - template.width(),
                        domElement.scrollWidth,
                    ),
                },
                750,
                'easeOutCubic',
            );
    }

    public shouldHideGoLeft(): boolean {
        return this.isSearchResults() || this.scrolledToLeftEdge;
    }

    public shouldHideGoRight(): boolean {
        return (
            this.isSearchResults() || // Search results don't use the "go left/right" arrows
            this.scrolledToRightEdge || // Hide when we've reached the right edge
            // Hide if no templates have loaded.
            // (Don't check "isPending()", because the dashboard does a hack where
            // It doesn't actually do a network request from here.)
            !this.templates.length
        );
    }

    public isSearchResults(): boolean {
        return this.groupType.type === TemplateGroupTypeEnum.SearchResults;
    }

    public isSearchable(): boolean {
        return [TemplateGroupTypeEnum.Category, TemplateGroupTypeEnum.RecentlyAdded].indexOf(this.groupType.type) > -1;
    }

    protected findTemplateGroupElement($event: JQueryEventObject): JQuery<HTMLElement> {
        return angular
            .element($event.target)
            .closest(TEMPLATE_GROUP_CONTAINER_CSS_SELECTOR)
            .find(TEMPLATE_GROUP_CSS_SELECTOR);
    }

    private getPage() {
        if (this.lastLoadedTemplate && !this.showBuildableTemplates) {
            return {
                previousDate: this.lastLoadedTemplate.createdAt,
                previousId: this.lastLoadedTemplate.id,
            };
        } else {
            return {};
        }
    }

    private async refreshLocalFilters(): Promise<void> {
        const data = await this.addLocationDraftInformation();
        this.templates.length = 0;

        // If we have no templates and this group is recently added and
        if (
            this.groupType.type === TemplateGroupTypeEnum.RecentlyAdded &&
            this.introService.isIntroActive('build') &&
            this.templates.length === 0
        ) {
            data.unshift(this.introDataService.getExampleGraphqlTemplate());
        }
        this.templates.push(...data);
        if (data.length < this.itemsRequested) {
            this.allLoaded = true;
        }
        if (data.length > 0) {
            this.lastLoadedTemplate = data[data.length - 1];
        }
    }

    private async addLocationDraftInformation(): Promise<TemplateGroupItem[]> {
        const data = this.templatesWithoutLocationInfo;

        if (this.location) {
            const allDrafts = await this.getDraftsForBuilderTemplates(
                this.location.graphqlId,
                data.filter(
                    template => template.currentRevisionId === null && template.buildableTemplateId === null,
                ).map(template => template.id),
            );

            const allDraftIds = allDrafts.map(draft => draft.builderTemplateId);

            const withRelated = data.map(template => ({
                ...template,
                hasRelatedDraft: allDraftIds.indexOf(template.id) >= 0,
            }));

            return withRelated;
        }

        return data;
    }

    private async getDraftsForBuilderTemplates(
        locationGraphqlId: string,
        builderTemplateIds: BuilderTemplateId[],
    ): Promise<Array<{ locationDraftId: string; builderTemplateId: BuilderTemplateId }>> {
        const gqlClient = this.graphqlService.getClient();

        const result = await gqlClient.query<
            GetGalleryTemplateDraftsForLocation,
            GetGalleryTemplateDraftsForLocationVariables
        >({
            fetchPolicy: 'network-only',
            query: GET_GALLERY_TEMPLATE_DRAFTS_FOR_LOCATION_QUERY,
            variables: {
                locationId: locationGraphqlId,
                templateIds: builderTemplateIds,
            },
        });
        if (result.errors) {
            this.sentryService.captureException(
                'Failed to fetch "GET_GALLERY_TEMPLATE_DRAFTS_FOR_LOCATION_QUERY" GraphQL query',
                {
                    errors: result.errors,
                },
            );
            throw new Error('Failed to fetch related gallery template drafts');
        }

        if (!result.data.location) {
            throw new Error('Could not fetch location information');
        }

        return result.data.location.locationDrafts.edges.map(edge => ({
            builderTemplateId: BuilderTemplateId.from(edge.node.builderTemplateId),
            locationDraftId: edge.node.id,
        }));
    }

    private async getAllTemplatesViaGraphql(queryParams: GalleryQueryParams): Promise<TemplateGroupItem[]> {
        if (!queryParams.client) {
            return [];
        }

        const clientId = ClientId.from(queryParams.client);
        const locationId = queryParams.location ? LocationId.from(queryParams.location) : null;

        const [builderTemplates, buildableTemplates] = await Promise.all([
            this.getBuilderTemplatesViaGraphql(queryParams, clientId),
            locationId && this.showBuildableTemplates
                ? this.getBuildableTemplatesViaGraphql(queryParams, clientId, locationId)
                : Promise.resolve([]),
        ]);

        // Combine and return the results
        const allTemplates = [
            ...builderTemplates,
            ...buildableTemplates,
        ].sort((first, second) => second.createdAt.getTime() - first.createdAt.getTime());

        return allTemplates;
    }

    private async getBuilderTemplatesViaGraphql(
        queryParams: GalleryQueryParams,
        clientId: ClientId,
    ): Promise<TemplateGroupItem[]> {
        const gqlClient = this.graphqlService.getClient();

        const result = await gqlClient.query<GetTemplatesForTemplateGroup, GetTemplatesForTemplateGroupVariables>({
            fetchPolicy: 'network-only',
            query: GET_TEMPLATES_FOR_TEMPLATE_GROUP,
            variables: {
                input: {
                    ...queryParams,
                    previousDate: queryParams.previousDate?.toISOString() ?? null,
                },
            },
        });

        // Always show non-deprecated builder templates. Only show deprecated builder templates if the flag is enabled.
        const builderTemplates = this.showDeprecatedBuilderTemplates
            ? result.data.builderTemplates
            : result.data.builderTemplates.filter(template => template.documentFormat !== BuilderDocumentFormat.email);

        return builderTemplates.map(template => mapBuilderTemplateToTemplateGroupItem(template, clientId));
    }

    private async getBuildableTemplatesViaGraphql(
        queryParams: GalleryQueryParams,
        clientId: ClientId,
        locationId: LocationId,
    ): Promise<TemplateGroupItem[]> {
        // Skip buildable templates if a builder template exclusive filter is enabled
        // In the future, we should explore which of these filters can be repurposed for builder templates
        if (
            queryParams.hidePublished ||
            queryParams.mobile ||
            queryParams.multiImage ||

            // These will be added soon
            queryParams.editableFields?.image ||
            queryParams.editableFields?.text ||
            queryParams.editableFields?.video
        ) {
            return [];
        }

        // Tags are always a case-insensitive partial OR match, so ['cde'] will match with 'abcdefg' etc
        // ExactTag should be an exact OR match, so ['tag'] will only match with 'tag' but not 'tag1' etc
        // This is a confusing system, so instead for new templates we're going to set 'exactMatch' explicitly

        // Tags come in from the '?search=' parameter as a string with spaces separating each tag, so they must be split
        const tagsArray = queryParams.tags?.split(' ') ?? [];
        const tagFilters = [
            ...queryParams.exactTag ? [{ matchType: TagMatchType.exact, tag: queryParams.exactTag }] : [],
            ...queryParams.tags ? tagsArray.map(tag => ({ matchType: TagMatchType.partial, tag })) : [],
        ];

        const gqlClient = this.graphqlService.getClient();

        const [buildableTemplatesResult, clientFeatures, builderTemplateFormats] = await Promise.all([
            gqlClient.query<GetBuildableTemplates, GetBuildableTemplatesVariables>({
                fetchPolicy: 'network-only',
                query: GET_BUILDABLE_TEMPLATES,
                variables: {
                    clientId: convertIdToUniversalNodeId('client', clientId),
                    filters: {
                        categories: queryParams.categories,
                        locationId,
                        onlySavedContentBuilds: queryParams.onlyLocationDraft,
                        // All users should be able to see Published templates
                        // But only managers should be able to see unpublished templates as well: DSL-3914
                        statuses: !this.mvIdentity.isManager()
                            ? [BuildableTemplateStatus.published]
                            : [BuildableTemplateStatus.published, BuildableTemplateStatus.unpublished],
                        tags: tagFilters,
                    },
                },
            }),
            gqlClient.query<GetEnabledIntegrationsForClient, GetEnabledIntegrationsForClientVariables>({
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                query: GET_ENABLED_INTEGRATIONS_FOR_CLIENT,
                variables: { id: convertIdToUniversalNodeId('client', clientId) },
            }),
            this.builderTemplateFormatResource.query().$promise,
        ]);

        // Filter out disabled integrations
        const enabledIntegrations = clientFeatures.data.client?.integrationConnection.edges
            .filter(edge => edge.node.isEnabled)
            .map(edge => edge.node.name);

        const filteredBuilderTemplateFormats = builderTemplateFormats.filter(format =>
            enabledIntegrations && format.platform
                ? enabledIntegrations.includes(format.platform.name)
                : false,
        );

        // Map the buildable templates to TemplateGroupItem
        let buildableTemplates = buildableTemplatesResult.data.client?.buildableTemplates.edges.map(edge =>
            mapBuildableTemplateToTemplateGroupItem(edge.node, clientId, filteredBuilderTemplateFormats),
        ) ?? [];

        // Builder templates apply format and platform filters on the back-end
        // We need to replicate this here for filtering to work properly

        // If any format OR platform matches, then include the template
        if (queryParams.formats?.length || queryParams.platforms?.length) {
            buildableTemplates = buildableTemplates.filter(template => {
                const matchesFormat = queryParams.formats?.length
                    ? template.formats.some(format => queryParams.formats!.includes(format.id))
                    : false;
                const matchesPlatform = queryParams.platforms?.length
                    ? template.formats.some(format => queryParams.platforms!.includes(format.platformId))
                    : false;
                return matchesFormat || matchesPlatform;
            });
        }

        return buildableTemplates;
    }

    private async getClientAndUserFeatures(clientId?: ClientId): Promise<{ clientFeatures: GetClientFeatures, userFeatures: GetUserFeatures }> {
        const gqlClient = this.graphqlService.getClient();

        const [clientFeaturesResult, userFeaturesResult] = await Promise.all([
            gqlClient.query<GetClientFeatures, GetClientFeaturesVariables>({
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                query: GET_CLIENT_FEATURES_QUERY,
                variables: { clientId: clientId ? convertIdToUniversalNodeId('client', clientId) : '' },
            }),
            gqlClient.query<GetUserFeatures>({
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                query: GET_USER_FEATURES_QUERY,
            }),
        ]);

        if (clientFeaturesResult.errors) {
            throw new Error('Failed to fetch client features');
        }

        if (userFeaturesResult.errors) {
            throw new Error('Failed to fetch user features');
        }

        return {
            clientFeatures: clientFeaturesResult.data,
            userFeatures: userFeaturesResult.data,
        };
    }
}

export function mapBuildableTemplateToTemplateGroupItem(
    // eslint-disable-next-line camelcase
    buildableTemplate: GetBuildableTemplates_client_buildableTemplates_edges_node,
    clientId: ClientId,
    builderTemplateFormats: ng.resource.IResourceArray<BuilderTemplateFormatResource>,
): TemplateGroupItem {
    return {
        buildableTemplateId: buildableTemplate.id,
        clientId,
        currentRevisionId: buildableTemplate.currentVersion.id, // Used as a parameter in the URL for the template
        graphqlId: buildableTemplate.id,
        compositeImage: buildableTemplate.currentVersion.thumbnailUrl
            ? { thumb256x256url: ProtocolRelativeUrl.from(buildableTemplate.currentVersion.thumbnailUrl) }
            : null,
        title: buildableTemplate.title,
        createdAt: new Date(buildableTemplate.currentVersion.createdAt),
        isDraft: buildableTemplate.status === BuildableTemplateStatus.unpublished,
        categories: buildableTemplate.categories.map(
            category => ({
                id: parseAndValidateUniversalNodeId<BuilderTemplateCategoryId>(category.id, 'BuilderTemplateCategory')!,
                title: category.title,
            })),
        tags: buildableTemplate.tags.map(
            tag => ({
                id: parseAndValidateUniversalNodeId<TagId>(tag.id, 'Tag')!,
                title: tag.title,
            })),

        // Required for compatibility
        documentFormat: BuilderDocumentFormat[buildableTemplate.templateType],
        isMultiImage: false,
        formats: builderTemplateFormats.filter(
            builderTemplateFormat =>
                builderTemplateFormat.platform &&
                builderTemplateFormat.type === buildableTemplate.templateType &&
                builderTemplateFormat.canPublishTo !== 'website' &&
                builderTemplateFormat.canPublishTo !== 'fitware', // Member Engager doesn't support buildable templates
        ).map(
            builderTemplateFormat => ({
                ...builderTemplateFormat,
                platform: {
                    label: builderTemplateFormat.platform!.label,
                    name: builderTemplateFormat.platform!.name as ServiceName,
                },
            }),
        ),

        // This is a nasty hack
        // There is no clean way of making this work without a larger refactor, as the core type is used everywhere
        // And this is directly cast to `BuilderTemplate` in `mvEmailBuilderCtrl`, so we can't just use a derivative
        // We filter out buildable templates in the places that require the ID, so this doesn't break anything
        id: BuilderTemplateId.from(-1),
    };
}
