/* eslint-disable max-lines */
import JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { gql } from '@apollo/client';
import {
    AspectRatio,
    isPrintDocument,
    BuilderDocumentFormat,
    PrintUnits,
    BuilderTemplateId,
    BuilderTemplateFormat,
    getFullLabelForFormat,
    ClientId,
    Untyped,
    PublishableService,
    ServiceName,
    ExportPlatforms,
    ExportPlatformsArray,
    ExternalService,
    FileFormatOptions,
    FilterableExportPlatforms,
    getFileFormatOptions,
    getPlatformIcon,
    ILocationAccessCheckPlatformName,
    AssignedLocation,
    assignedLocationToLocationIdHierarchy,
    LocationId,
    LocationIdHierarchy,
    GalleryPlannerDetails,
    PrintProvider,
    MAX_MULTI_IMAGE_COUNT,
    MAX_INSTAGRAM_CAROUSEL_MEDIA_COUNT,
    VALID_INSTAGRAM_STORY_RATIO,
    isWithinAspectRatioTolerance,
} from '@deltasierra/shared';

import { dataUrlToBlob } from '@deltasierra/web-image-utilities';
import type {
    IAngularEvent,
    IFilterService,
    ILocationService,
    IPromise,
    IQResolveReject,
    IQService,
    IRootScopeService,
    IScope,
} from 'angular';

import { MvAuth } from '../../account/mvAuth';
import { MvIdentity } from '../../account/mvIdentity';
import {
    $filterSID,
    $locationSID,
    $qSID,
    $rootScopeSID,
    $scopeSID,
    $timeoutSID,
    $windowSID,
} from '../../common/angularData';
import { FileUtils } from '../../common/fileUtils';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { ProgressModalService } from '../../common/progressModalService';
import { SentryService } from '../../common/sentryService';
import { UploadContext } from '../../common/uploadService';
import { GraphqlService } from '../../graphql/GraphqlService';
import { I18nService } from '../../i18n/i18nService';
import { PrintProviderApiClient } from '../../integration/auth/printProviderApiClient';
import { LocalPublishService } from '../../integration/publish/localPublishService';
import { IntroService } from '../../intro/introService';
import { MvPlanner } from '../../planner/mvPlanner';
import { PlannerUIService } from '../../planner/plannerUIService';
import { shoppingCartItemChangeEvent } from '../../shoppingCart';
import { BuilderConstants, builderConstantsSID } from '../builderConstants';
import { ContentBuilder } from '../contentBuilder';
import { ExportData } from '../contentBuilderRenderer';
import { Four51ApiClient } from '../four51/four51ApiClient';
import { GifWriterService } from '../gifWriterService';
import { getFormatByName } from '../imageFormats';
import { FileCache } from '../imageLoaderService';
import { PdfBuilderService } from '../print/pdfBuilderService';
import { FileFormatChoice } from './mvContentBuilderFileFormatCtrl';
import { PrintPublishData, PublishImageFormat } from './print/basePublishPrintController';
import { PlatformAvailabilityAndAccess, PublishingPlatformsService } from './publishingPlatformsService';
import { PublishResult, PublishTypes } from './publishResult';
import { GetHasDirectPublishPermissionsForPublishController } from './__graphqlTypes/GetHasDirectPublishPermissionsForPublishController';
import { GetIsInstagramEnabled } from './__graphqlTypes/GetIsInstagramEnabled';
import { GetMultiPublishConfig } from './__graphqlTypes/GetMultiPublishConfig';
import { GetInstagramStoriesEnabled } from './__graphqlTypes/GetInstagramStoriesEnabled';

const GET_IS_INSTAGRAM_ENABLED_QUERY = gql`
    query GetIsInstagramEnabled {
        config {
            id
            features {
                publishing {
                    instagramDirectPublishing
                }
            }
        }
    }
`;

const GET_MULTIPUBLISH_ENABLED = gql`
    query GetMultiPublishConfig {
        config {
            id
            features {
                publishing {
                    facebookInstagram
                }
            }
        }
    }
`;

const GET_INSTAGRAM_STORIES_ENABLED = gql`
    query GetInstagramStoriesEnabled {
        config {
            id
            features {
                publishing {
                    instagramStories
                }
            }
        }
    }
`;

const GET_HAS_DIRECT_PUBLISH_PERMISSIONS = gql`
    query GetHasDirectPublishPermissionsForPublishController($locationId: ID!) {
        location(id: $locationId) {
            id
            instagram {
                ... on Instagram {
                    id
                    hasDirectPublishPermissions
                }
            }
        }
    }
`;

export interface BuilderFileFormatOptions {
    imageOptions: FileFormatOptions;
    canPublishToFilter: ServiceName[];
}

const VIEWS = {
    BUILDER: 'BUILDER',
    FILE_FORMAT: 'FILE_FORMAT',
    MULTIPLE_LOCATIONS: 'MULTIPLE_LOCATIONS',
    PLATFORMS: 'PLATFORMS',
    PROCESS_VIDEO: 'PROCESS_VIDEO',
    PUBLISHED: 'PUBLISHED',
    PUBLISHED_TO_FOUR51: 'PUBLISHED_TO_FOUR51',
    PUBLISHED_TO_SHOPPING_CART: 'PUBLISHED_TO_SHOPPING_CART',
    PUBLISH_TO_PLATFORM: 'PUBLISH_TO_PLATFORM',
};
const PUBLISH_AGAIN = {
    DIFFERENT_TEMPLATE: 'DIFFERENT_TEMPLATE',
    LATER: 'LATER',
    NO: 'NO',
    YES: 'YES',
};

export class MvContentBuilderPublishCtrl {
    public static readonly $inject: string[] = [
        $rootScopeSID,
        $scopeSID,
        $locationSID,
        $windowSID,
        $filterSID,
        $qSID,
        $timeoutSID,
        MvAuth.SID,
        MvIdentity.SID,
        MvPlanner.SID,
        PlannerUIService.SID,
        PrintProviderApiClient.SID,
        InteractionUtils.SID,
        builderConstantsSID,
        GifWriterService.SID,
        LocalPublishService.SID,
        FileUtils.SID,
        PdfBuilderService.SID,
        MvNotifier.SID,
        IntroService.SID,
        ProgressModalService.SID,
        I18nService.SID,
        PublishingPlatformsService.SID,
        Four51ApiClient.SID,
        GraphqlService.SID,
        SentryService.SID,
    ];

    public static SID = 'mvContentBuilderPublishCtrl';

    public fetchCanDownloadTemplate = this.interactionUtils.createFuture(
        'Check for client exclusive SKUs / print provider',
        (context: { clientId: ClientId; builderTemplateId: BuilderTemplateId }) =>
            this.printProviderApiClient
                .getClientExclusivePrintProvider(context.clientId, context.builderTemplateId)
                .then(result => {
                    if (result.printProvider) {
                        this.canDownloadTemplate = false;
                        this.exclusivePrintProvider = result.printProvider;

                        if (this.isPlatformAvailable('print') && !this.canDownloadTemplate) {
                            this.platform = ExportPlatforms.Print;
                        }
                    }
                }),
    );

    public VIEWS = VIEWS;

    public ExportPlatforms = ExportPlatforms;

    public getPlatformIcon = getPlatformIcon;

    public PUBLISH_AGAIN = PUBLISH_AGAIN;

    public templateId!: BuilderTemplateId;

    public plannerDetails?: GalleryPlannerDetails;

    public location!: AssignedLocation;

    public selectedBuilderTemplateFormats!: BuilderTemplateFormat[];

    public contentBuilder!: ContentBuilder;

    public fileCache!: FileCache;

    public view: string = this.VIEWS.PLATFORMS;

    // VIEWS
    public platform: ExternalService | ExternalService[] | null = null;

    // PLATFORMS
    public publishedPlatforms?: string[];

    public publishAgain: string | null = null;

    // PUBLISH_AGAIN
    public publishResult?: PublishResult;

    public publishTypes = PublishTypes;

    public loading = {
        authorizedAndConfiguredLocationsForFacebook: false,
        canAccessMultipleLocations: false,
        localImagePublish: false,
        publishedPlatforms: false,
        setPlannerStatusToPlanned: false,
    };

    public exportData: ExportData | null = null;

    public fileFormatOptions: BuilderFileFormatOptions | null = null;

    public fileFormatChoice: FileFormatChoice | null = null;

    public canAccessMultipleLocations: boolean | null = null;

    public locations!: LocationIdHierarchy[];

    public locationIds: string[] = [];

    public platformAccess?: PlatformAvailabilityAndAccess;

    public multipleLocationsRequired = false;

    public isExportingPrintDocument = false;

    public uploadContext: UploadContext | null = null;

    public canDownloadTemplate = true;

    public exclusivePrintProvider?: PrintProvider | null;

    public isInstagramDirectPublishingEnabled = false;

    public hasInstagramDirectPublishPermissions = false;

    public printPublishData?: PrintPublishData;

    public isFacebookInstagramMultipublishEnabled = false;

    public isInstagramStoriesEnabled = false;

    public doesPdfRequireFallbackFonts = false;

    public fetchPlatformAccess = this.interactionUtils.createFuture(
        'fetch platform access',
        (context: {
            builderTemplateFormats: BuilderTemplateFormat[];
            clientId: ClientId;
            isAnimation: boolean;
            locationId: LocationId;
        }) =>
            this.publishingPlatformsService
                .fetchPlatformAccess(
                    context.locationId,
                    context.clientId,
                    context.builderTemplateFormats,
                    context.isAnimation,
                )
                .then(result => {
                    this.platformAccess = result;
                    return this.platformAccess;
                }),
    );

    public checkPdfRequiresFallbackFontsFuture = this.interactionUtils.createFuture(
        'check pdf required fallback fonts',
        async () => {
            const options = {
                clientId: this.location.clientId,
                document: this.contentBuilder.document,
                textSubstitutionValues: this.contentBuilder.textSubstitutionValues,
            };
            // Run the checking function
            this.doesPdfRequireFallbackFonts = await this.pdfBuilderService.checkPdfRequiresFallbackFonts(options);
        },
    );

    /* eslint-enable @typescript-eslint/no-invalid-this */

    public twitterMaxMultiImageCount = MAX_MULTI_IMAGE_COUNT;

    private PUBLISH_CANVAS_ID = 'contentBuilderPublishCanvas';

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $rootScope: IRootScopeService,
        private readonly $scope: IScope,
        private readonly $location: ILocationService,
        private readonly $window: ng.IWindowService,
        private readonly $filter: IFilterService,
        private readonly $q: IQService,
        private readonly $timeout: ng.ITimeoutService,
        private readonly mvAuth: MvAuth,
        private readonly mvIdentity: MvIdentity,
        private readonly mvPlanner: MvPlanner,
        private readonly plannerUIService: PlannerUIService,
        private readonly printProviderApiClient: PrintProviderApiClient,
        private readonly interactionUtils: InteractionUtils,
        private readonly builderConstants: BuilderConstants,
        private readonly gifWriterService: GifWriterService,
        private readonly localPublishService: LocalPublishService,
        private readonly fileUtils: FileUtils,
        private readonly pdfBuilderService: PdfBuilderService,
        private readonly notifier: MvNotifier,
        private readonly introService: IntroService,
        private readonly progressModalService: ProgressModalService,
        private readonly i18n: I18nService,
        private readonly publishingPlatformsService: PublishingPlatformsService,
        private readonly four51ApiClient: Four51ApiClient,
        private readonly graphqlService: GraphqlService,
        private readonly sentryService: SentryService,
    ) {}

    // eslint-disable-next-line max-statements
    public $onInit(): void {
        this.locations = [assignedLocationToLocationIdHierarchy(this.location)];
        this.$scope.$on(this.builderConstants.EVENTS.FILE_FORMAT_CHOSEN, this.builderFileFormatChosenEvent.bind(this));
        this.$scope.$on(this.builderConstants.EVENTS.PUBLISH_PUBLISHED, this.builderPublished.bind(this));
        this.contentBuilder.zoomToFitCanvas(this.PUBLISH_CANVAS_ID);
        void this.getPublishedPlatformsIfRequired();
        void this.getCanAccessMultipleLocations();
        void this.refreshPlatformAccess();
        void this.fetchIsInstagramDirectPublishingEnabled();
        void this.fetchHasDirectPublishPermissions();
        void this.getFacebookInstagramMultiPublishEnabled();
        void this.getInstagramStoriesEnabled();
        this.renderToPublishCanvas();
        if (!this.isIntroActive()) {
            void this.fetchCanDownloadTemplate.run({
                builderTemplateId: this.templateId,
                clientId: this.location.clientId,
            });
        }

        const windowFocused = () => {
            if (this.view === this.VIEWS.PLATFORMS) {
                return this.refreshPlatformAccess();
            }
            return Promise.resolve();
        };
        this.$window.addEventListener('focus', windowFocused);
        this.$scope.$on('$destroy', () => {
            this.$window.removeEventListener('focus', windowFocused);
        });

        // This little hack here waits for the platform type to be available then runs the check
        this.$scope.$watch(
            () => this.platformAccess?.isPlatformAvailable('print'),
            this.checkPdfRequiresFallbackFonts.bind(this),
        );
        this.publishPublished = this.publishPublished.bind(this);
        this.cancelPublish = this.cancelPublish.bind(this);
        this.multiPlatformPublishPublished = this.multiPlatformPublishPublished.bind(this);
    }

    public isIntroActive(): boolean {
        return this.introService.isIntroActive('build');
    }

    public renderToPublishCanvas(): void {
        this.contentBuilder.renderSimpleToCanvasId(this.PUBLISH_CANVAS_ID);
    }

    public responsiveZoomReset(): void {
        this.contentBuilder.zoomToFitCanvas(this.PUBLISH_CANVAS_ID);
        this.renderToPublishCanvas();
    }

    public isAnyPlatformAccessCheckLoading(): boolean {
        return !!this.platformAccess && this.platformAccess.isAnyPlatformAccessCheckLoading();
    }

    public openPaymentWindow(url: URL): void {
        this.$window.open(url.href, '_blank');
    }

    public backToGallery(): void {
        this.finish();
        this.$location.search({});
        this.$location.path('/builderTemplateGallery');
    }

    public checkoutWithStripe(): void {
        this.finish();
        this.$location.search({});
        this.$location.path('/checkout');
    }

    public checkoutWithFour51(): IPromise<void> {
        const url = this.four51ApiClient.getLogonToFour51Url(this.location.id);
        const timeoutDuration = 2000;
        this.$window.open(url, '_blank');
        return this.$timeout(() => {
            this.finish();
            this.$location.search({});
            this.$location.path('/builderTemplateGallery'); // Or back to planner?
        }, timeoutDuration);
    }

    public publishPublished(publishedResult: PublishResult): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_PUBLISHED, publishedResult);
    }

    public multiPlatformPublishPublished(publishedResults: PublishResult[]): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_PUBLISHED, publishedResults);
    }

    public cancelPublish(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    public constructPlannerUrl(): string | undefined {
        if (this.plannerDetails) {
            return this.plannerUIService.getPlannerUrl(this.plannerDetails);
        } else {
            return undefined;
        }
    }

    public isPlatformAvailable(platform: PublishableService): boolean {
        const isPlatformAvailable = !!this.platformAccess && this.platformAccess.isPlatformAvailable(platform);
        if (platform === 'local') {
            return isPlatformAvailable;
        } else {
            return this.mvIdentity.canPublish() && isPlatformAvailable;
        }
    }

    public isFacebookInstagramMultiPublishAvailable(): boolean {
        const canPublish = this.isPlatformAvailable('facebook') && this.isPlatformAvailable('instagram');
        const hasAppropriateFormat = this.selectedBuilderTemplateFormats.some(
            format =>
                !!format.platform && (format.platform.name === 'facebook' || format.platform.name === 'instagram'),
        );
        return canPublish && hasAppropriateFormat;
    }

    public canPublishToFacebookAndInstagram(): boolean {
        return (
            this.hasPlatformAccess('instagram') &&
            this.hasPlatformAccess('facebook') &&
            this.hasInstagramDirectPublishPermissions &&
            this.canPublishToInstagramDirect()
        );
    }

    public hasPlatformAccess(platform: ILocationAccessCheckPlatformName): boolean {
        return !!this.platformAccess && this.platformAccess.hasPlatformAccess(platform);
    }

    public canPublishToInstagramDirect(): boolean {
        return this.getPublishToInstagramDirectValidationErrors().length === 0;
    }

    public getPublishToInstagramDirectValidationErrors(): string[] {
        if (this.isExportingVideo()) {
            return this.getPublishVideoToInstagramDirectValidationErrors();
        } else if (this.contentBuilder.document.format === BuilderDocumentFormat.content) {
            return this.getPublishImageToInstagramDirectValidationErrors();
        } else {
            return [];
        }
    }

    public getPublishVideoToInstagramDirectValidationErrors(): string[] {
        // @see https://developers.facebook.com/docs/instagram-api/reference/ig-user/media
        const validationErrors = [];

        if (!this.hasInstagramDirectPublishPermissions) {
            validationErrors.push(this.i18n.text.build.publish.instagram.missingPermissionsHelpText());
        }

        const longestVideoDuration = this.contentBuilder.videoPlayer.getLongestVideoDuration();
        const INSTAGRAM_MIN_VIDEO_LENGTH_SECONDS = 3;
        const INSTAGRAM_MAX_VIDEO_LENGTH_SECONDS = 90;
        if (
            longestVideoDuration < INSTAGRAM_MIN_VIDEO_LENGTH_SECONDS ||
            longestVideoDuration >= INSTAGRAM_MAX_VIDEO_LENGTH_SECONDS
        ) {
            validationErrors.push(this.i18n.text.build.publish.instagram.videoDurationHelpText());
        }

        const aspectRatio = AspectRatio.of(
            this.contentBuilder.document.dimensions.width,
            this.contentBuilder.document.dimensions.height,
        );

        /*
         * Instagram only supports direct publishing for reels with an aspect ratio between 0.1:1 and 10:1.
         * Minimum aspect ratio [cols / rows]: 0.1:1 (0.1 / 1 = 0.1)
         * Maximum aspect ratio [cols / rows]: 10:1 (10 / 1 = 10)
         * @see https://developers.facebook.com/docs/instagram-api/reference/ig-user/media
         */
        if (aspectRatio.ratio < 0.1 || aspectRatio.ratio > 10) {
            validationErrors.push(this.i18n.text.build.publish.instagram.aspectRatioVideoHelpText());
        }

        /*
         * Picture size:
         * Maximum width (horizontal pixels): 1920
         */
        if (this.contentBuilder.document.dimensions.width > 1920) {
            validationErrors.push(this.i18n.text.build.publish.instagram.invalidDimensionsWidthHelpText());
        }

        return validationErrors;
    }

    public getPublishImageToInstagramDirectValidationErrors(): string[] {
        const validationErrors = [];

        const aspectRatio = AspectRatio.of(
            this.contentBuilder.document.dimensions.width,
            this.contentBuilder.document.dimensions.height,
        );
        /*
         * Instagram only supports direct publishing for ratios between 1.91:1 and 4:5.
         *
         * @see https://help.instagram.com/1631821640426723
         * @see https://developers.facebook.com/docs/instagram-api/reference/ig-user/media
         */
        if (aspectRatio.ratio < 4 / 5 || aspectRatio.ratio > 1.91 / 1) {
            validationErrors.push(this.i18n.text.build.publish.instagram.aspectRatioHelpText());
        }

        if (!this.isMultiImageCountWithinInstagramLimits()) {
            validationErrors.push(
                this.i18n.text.build.publish.instagram.exceededMaxImageCountHelpText({
                    count: MAX_INSTAGRAM_CAROUSEL_MEDIA_COUNT,
                }),
            );
        }

        return validationErrors;
    }

    public canPublishToInstagramStoryDirect(): boolean {
        return this.getPublishToInstagramStoryDirectValidationErrors().length === 0;
    }

    public getPublishToInstagramStoryDirectValidationErrors(): string[] {
        if (this.isExportingVideo()) {
            return this.getPublishVideoToInstagramStoryDirectValidationErrors();
        } else if (this.contentBuilder.document.format === BuilderDocumentFormat.content) {
            return this.getPublishImageToInstagramStoryDirectValidationErrors();
        } else {
            return [];
        }
    }

    public getPublishVideoToInstagramStoryDirectValidationErrors(): string[] {
        // @see https://developers.facebook.com/docs/instagram-api/reference/ig-user/media
        const validationErrors = [];

        if (!this.hasInstagramDirectPublishPermissions) {
            validationErrors.push(this.i18n.text.build.publish.instagram.missingPermissionsHelpText());
        }

        const longestVideoDuration = this.contentBuilder.videoPlayer.getLongestVideoDuration();
        const INSTAGRAM_MIN_VIDEO_LENGTH_SECONDS = 3;
        const INSTAGRAM_MAX_VIDEO_LENGTH_SECONDS = 60;
        if (
            longestVideoDuration < INSTAGRAM_MIN_VIDEO_LENGTH_SECONDS ||
            longestVideoDuration >= INSTAGRAM_MAX_VIDEO_LENGTH_SECONDS
        ) {
            validationErrors.push(this.i18n.text.build.publish.instagram.storyVideoDurationHelpText());
        }

        const aspectRatio = AspectRatio.of(
            this.contentBuilder.document.dimensions.width,
            this.contentBuilder.document.dimensions.height,
        );

        /*
         * We only allow stories with a 9:16 (0.5625:1) aspect ratio
         * E.g. 1080 x 1920, 900 x 1600, 720 x 1280
         * @see https://digitalstack.atlassian.net/browse/DSL-2416
         */
        if (!isWithinAspectRatioTolerance(aspectRatio.ratio, VALID_INSTAGRAM_STORY_RATIO)) {
            validationErrors.push(this.i18n.text.build.publish.instagram.aspectRatioStoryHelpText());
        }

        /*
         * Picture size:
         * Maximum width (horizontal pixels): 1920
         */
        if (this.contentBuilder.document.dimensions.width > 1920) {
            validationErrors.push(this.i18n.text.build.publish.instagram.invalidDimensionsWidthHelpText());
        }

        return validationErrors;
    }

    public getPublishImageToInstagramStoryDirectValidationErrors(): string[] {
        const validationErrors = [];

        if (this.isExportingMultiImage()) {
            validationErrors.push(this.i18n.text.build.publish.instagram.storyMultiImageHelpText())
        }
        if (!this.hasInstagramDirectPublishPermissions) {
            validationErrors.push(this.i18n.text.build.publish.instagram.missingPermissionsHelpText());
        }

        const aspectRatio = AspectRatio.of(
            this.contentBuilder.document.dimensions.width,
            this.contentBuilder.document.dimensions.height,
        );

        /*
         * We only allow stories with a 9:16 (0.5625:1) aspect ratio
         * E.g. 1080 x 1920, 900 x 1600, 720 x 1280
         * @see https://digitalstack.atlassian.net/browse/DSL-2416
         */
        if (!isWithinAspectRatioTolerance(aspectRatio.ratio, VALID_INSTAGRAM_STORY_RATIO)) {
            validationErrors.push(this.i18n.text.build.publish.instagram.aspectRatioStoryHelpText());
        }

        if (!this.isMultiImageCountWithinInstagramLimits()) {
            validationErrors.push(
                this.i18n.text.build.publish.instagram.exceededMaxImageCountHelpText({
                    count: MAX_INSTAGRAM_CAROUSEL_MEDIA_COUNT,
                }),
            );
        }

        return validationErrors;
    }

    public hasAnyPlatformFailedAccess(): boolean {
        if (!this.platformAccess || this.platformAccess.isAnyPlatformAccessCheckLoading()) {
            return false;
        }

        return this.platformAccess.isAnyPlatformAccessCheckNegative();
    }

    public platformSupportsMultiImageLength(platform: PublishableService): boolean {
        if (!this.contentBuilder.document.multiImage) {
            return true;
        }
        if (platform === 'twitter') {
            return this.contentBuilder.document.multiImage.length <= MAX_MULTI_IMAGE_COUNT;
        } else {
            return true;
        }
    }

    public getPlatformFailedAccessHelp(): string {
        return this.i18n.text.build.publish.noAccessHelp();
    }

    public refreshPlatformAccess(): ng.IPromise<void> {
        return this.fetchPlatformAccess
            .run({
                builderTemplateFormats: this.selectedBuilderTemplateFormats,
                clientId: this.location.clientId,
                isAnimation: !!this.contentBuilder.document.animation,
                locationId: this.location.id,
            })
            .then(platformAccess => platformAccess.run());
    }

    public async checkPdfRequiresFallbackFonts(): Promise<void> {
        // Only check on the print platform
        if (this.isPlatformAvailable('print')) {
            // Run the fetching check function
            await this.checkPdfRequiresFallbackFontsFuture.run({});
        }
    }

    public updateSelectedLocations(locations: LocationIdHierarchy[]): void {
        this.locations = locations;
        this.locationIds = locations.map(loc => loc.locationGraphqlId);
    }

    public isPlatformAlreadyPublished(platform: string): boolean | undefined {
        return this.publishedPlatforms && this.publishedPlatforms.indexOf(platform) > -1;
    }

    public isLocationConfiguredForInstagramMobilePublish(): boolean {
        return !!this.location.instagramPageId && !!this.location.instagramPageName;
    }

    public isMultiImageCountWithinInstagramLimits(): boolean {
        return (
            !this.contentBuilder.document.multiImage ||
            this.contentBuilder.document.multiImage.length <= MAX_INSTAGRAM_CAROUSEL_MEDIA_COUNT
        );
    }

    public producePdf(
        updateCallback: (value: number, max: number) => void,
        exclusiveArtworkReview?: boolean,
    ): ng.IPromise<PDFKit.PDFDocument> {
        if (!this.fileFormatChoice) {
            return this.$q.reject(new Error());
        } else {
            const dimensions = {
                height: this.fileFormatChoice.height,
                unit: PrintUnits.pixels as any,
                width: this.fileFormatChoice.width,
            };

            const options = {
                clientId: this.location.clientId,
                dimensions,
                document: this.contentBuilder.document,
                imageCache: this.contentBuilder.imageCache,
                includeBleedAndTrim: exclusiveArtworkReview ? false : this.fileFormatChoice.includeBleed,
                textSubstitutionValues: this.contentBuilder.textSubstitutionValues,
                updateProgress: updateCallback,
            };

            return this.pdfBuilderService.exportToPdf(options);
        }
    }

    public generatePrintPdf(exclusiveArtworkReview?: boolean): IPromise<PDFKit.PDFDocument> {
        return this.$q(async (resolve: ng.IQResolveReject<PDFKit.PDFDocument>, reject: ng.IQResolveReject<any>) => {
            // TODO: translate the title
            const progressModal = this.progressModalService.showModal({
                max: 1,
                status: '',
                title: 'Exporting Document',
            });
            const callback = (value: number, max: number) => progressModal.update({ max, value });
            return this.producePdf(callback, exclusiveArtworkReview)
                .then(pdf => {
                    progressModal.close();
                    resolve(pdf);
                })
                .catch(err => {
                    progressModal.close();
                    reject(err);
                });
        });
    }

    public doesPrintContainInvalidCharacters(): boolean {
        return this.doesPdfRequireFallbackFonts;
    }

    public choosePlatform(): void {
        if (
            !this.plannerDetails &&
            this.canAccessMultipleLocations &&
            this.isMultipleLocationsSupported() &&
            this.multipleLocationsRequired
        ) {
            return this.goToMultipleLocationPickerView();
        } else {
            this.locations = [assignedLocationToLocationIdHierarchy(this.location)];
            this.locationIds = this.locations.map(loc => loc.locationGraphqlId);
        }

        return this.goToFileFormatView();
    }

    public isMultipleLocationsSupported(): boolean | null {
        const supportedPlatforms = [
            this.ExportPlatforms.Facebook,
            this.ExportPlatforms.InstagramDirect,
            this.ExportPlatforms.InstagramStoryDirect,
            this.ExportPlatforms.Twitter,
            this.ExportPlatforms.LinkedIn,
            this.ExportPlatforms.GoogleMyBusiness,
        ];
        return Array.isArray(this.platform)
            ? supportedPlatforms.some(supported => (this.platform as ExternalService[]).includes(supported))
            : this.platform &&
                  supportedPlatforms.indexOf(this.platform) > -1 && // Supported formats
                  [BuilderDocumentFormat.print].indexOf(this.contentBuilder.document.format) === -1; // Unsupported formats
    }

    public chooseLocations(locations: LocationIdHierarchy[]): void {
        this.locations = locations;
        this.locationIds = locations.map(loc => loc.locationGraphqlId);
        return this.goToFileFormatView();
    }

    public builderFileFormatChosenEvent(event: IAngularEvent, fileFormatChoice: FileFormatChoice): IPromise<void> {
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        this.fileFormatChoice = fileFormatChoice;
        if (this.isExportingVideo()) {
            this.setView(this.VIEWS.PUBLISH_TO_PLATFORM);
        } else if (isPrintDocument(this.contentBuilder.document) && this.platform === this.ExportPlatforms.Local) {
            return this.exportToPrintDocument();
        } else {
            return this.prepareImageAndGoToPublish();
        }
        return Promise.resolve();
    }

    public async prepareImage(): Promise<ExportData[]> {
        if (this.isExportingAnimation()) {
            const exportData = await this.writeAnimationToGif();
            return [exportData];
        } else if (this.isExportingMultiImage()) {
            return this.getScaledMultiImageData();
        } else {
            const exportData = await this.getScaledImageData();
            return [exportData];
        }
    }

    public generateImageFileName(imageFormat?: PublishImageFormat, customFileName?: string): string {
        let fileName;
        const extension = imageFormat ? `.${imageFormat.fileExtension}` : '.png';
        if (customFileName) {
            fileName = customFileName;
        } else if (this.contentBuilder.document.title) {
            fileName = this.contentBuilder.document.title;
        } else if (this.selectedBuilderTemplateFormats && this.selectedBuilderTemplateFormats.length > 0) {
            // Just grab the first format
            const format = this.selectedBuilderTemplateFormats[0];
            fileName = getFullLabelForFormat(format);
        } else {
            fileName = 'image';
        }
        if (this.plannerDetails && this.plannerDetails.date) {
            fileName += this.$filter('date')(this.plannerDetails.date, ' - yyyy-MM-dd');
        }
        fileName += extension;
        return fileName;
    }

    public isExportingVideo(): boolean {
        return this.contentBuilder.document.format === BuilderDocumentFormat.video;
    }

    public isExportingMultiImage(): boolean {
        return !!this.contentBuilder.document.multiImage;
    }

    public getNotificationDescription(result: PublishResult): string {
        let desc = '';
        if (result.notification) {
            const assignedUser = `${result.notification.assignedUser.firstName} ${result.notification.assignedUser.lastName}`;
            if (result.scheduledTime) {
                desc = this.i18n.text.build.publish.willReceiveScheduledNotification({
                    assignedUser,
                    platform: result.platform.displayName,
                    scheduledTime: this.getScheduledTimeDisplayText(result.scheduledTime),
                });
            } else {
                desc = this.i18n.text.build.publish.willReceiveNotification({
                    assignedUser,
                    platform: result.platform.displayName,
                });
            }
        } // TODO: display something if there's no notification

        return desc;
    }

    public getPublishedDescription(): string {
        let desc = '';
        const result = this.publishResult;
        if (!result) {
            return desc;
        }

        if (result.notification !== null) {
            desc = this.getNotificationDescription(result);
        } else if (result.platform === ExportPlatforms.Local) {
            desc = this.i18n.text.build.publish.savedSuccessfully({
                platform: result.platform.displayName,
            });
        } else if (result.platform === ExportPlatforms.Print) {
            desc = this.i18n.text.build.publish.print.successfullySent();
        } else if (this.isExportingVideo() && this.isMultipleLocationsSupported()) {
            if (result.scheduledTime) {
                desc = this.i18n.text.build.publish.multipleLocations.scheduledVideosSuccessfully({
                    platform: result.platform.displayName,
                    scheduledTime: this.getScheduledTimeDisplayText(result.scheduledTime),
                });
            } else {
                desc = this.i18n.text.build.publish.multipleLocations.publishedVideosSuccessfully({
                    platform: result.platform.displayName,
                });
            }
        } else if (result.scheduledTime) {
            desc = this.i18n.text.build.publish.scheduledSuccessfully({
                platform: result.platform.displayName,
                scheduledTime: this.getScheduledTimeDisplayText(result.scheduledTime),
            });
        } else {
            desc = this.i18n.text.build.publish.publishedSuccessfully({
                platform: result.platform.displayName,
            });
        }

        return desc;
    }

    public choosePublishAgain(): void {
        // TODO: translate the "handleRemote" descriptions
        if (this.publishAgain === this.PUBLISH_AGAIN.YES) {
            this.getPublishedPlatformsIfRequired();
            this.setView(this.VIEWS.PLATFORMS);
            return undefined;
        } else if (
            this.publishAgain === this.PUBLISH_AGAIN.DIFFERENT_TEMPLATE &&
            this.plannerDetails &&
            this.plannerDetails.id
        ) {
            void this.interactionUtils
                .handleRemote(
                    this,
                    'update planner status',
                    'setPlannerStatusToPlanned',
                    this.mvPlanner.setStatusToPlanned(this.plannerDetails.id),
                )
                .then(this.goToGallery.bind(this));
            return this.finish();
        } else if (this.publishAgain === this.PUBLISH_AGAIN.LATER && this.plannerDetails && this.plannerDetails.id) {
            void this.interactionUtils
                .handleRemote(
                    this,
                    'update planner status',
                    'setPlannerStatusToPlanned',
                    this.mvPlanner.setStatusToPlanned(this.plannerDetails.id),
                )
                .then(this.goToPlanner.bind(this));
            return this.finish();
        } else {
            this.goToPlanner();
            return this.finish();
        }
    }

    protected broadcastShoppingCartItemChangeEvent(): void {
        shoppingCartItemChangeEvent.broadcast(this.$rootScope, {});
    }

    private getPublishedPlatformsIfRequired() {
        if (this.plannerDetails && this.plannerDetails.id) {
            return this.interactionUtils
                .handleRemote(
                    this,
                    'retrieve published platforms',
                    'publishedPlatforms',
                    this.mvPlanner.getPlannerPublishedPlatforms(this.plannerDetails.id),
                )
                .then((result: { publishedPlatforms: Untyped }) => {
                    this.publishedPlatforms = result.publishedPlatforms;
                });
        }
        return Promise.resolve();
    }

    private getCanAccessMultipleLocations() {
        return this.interactionUtils
            .handleRemoteSimple(
                this,
                'retrieve multiple location access',
                'canAccessMultipleLocations',
                this.mvAuth.canAccessMultipleLocations(),
            )
            .then((result: { result: boolean }) => {
                this.canAccessMultipleLocations = result.result;
            });
    }

    private exportToPrintDocument(): IPromise<void> {
        return this.$q<void>(async (resolve: IQResolveReject<void>, reject: IQResolveReject<void>) => {
            // TODO: translate the title
            const progressModal = this.progressModalService.showModal({
                max: 1,
                status: '',
                title: 'Exporting Document',
            });

            this.isExportingPrintDocument = true;

            const finallyCallback = () => {
                this.isExportingPrintDocument = false;
                progressModal.close();
            };

            let objectUrl: string | undefined;
            return (
                this.producePdf((value: number, max: number) => progressModal.update({ max, value }))
                    .then(pdf => this.pdfBuilderService.pdfToBlob(pdf))
                    .then((blobStream: BlobStream.IBlobStream) => {
                        const blob = blobStream.toBlob('application/pdf');
                        objectUrl = URL.createObjectURL(blob);
                        return this.fileUtils.downloadFile(
                            objectUrl,
                            this.generateImageFileName({ fileExtension: 'pdf', name: 'pdf' }),
                        );
                    })
                    .then(() =>
                        this.localPublishService.publishLocal(this.location.id, {
                            builderTemplateId: this.templateId,
                            plannerId: this.plannerDetails && this.plannerDetails.id,
                        }),
                    )
                    .then(() =>
                        this.$timeout(() => {
                            this.builderPublished(null, new PublishResult(ExportPlatforms.Local, null));
                            resolve();
                        }, 800),
                    )
                    .catch(err => {
                        // TODO: translate this error message
                        this.notifier.unexpectedErrorWithData('Export to PDF failed', err);
                        reject(err);
                    })

                    .finally(() => {
                        finallyCallback();
                        if (objectUrl) {
                            URL.revokeObjectURL(objectUrl);
                        }
                    })
            );
        });
    }

    private goToFileFormatView() {
        this.fileFormatOptions = this.getAvailableFileFormatOptions();
        this.setView(this.VIEWS.FILE_FORMAT);
    }

    private goToMultipleLocationPickerView() {
        this.setView(this.VIEWS.MULTIPLE_LOCATIONS);
    }

    private getAvailableFileFormatOptions(): BuilderFileFormatOptions {
        const platform = this.platform;
        let canPublishToFilter: ServiceName[] = [];
        if (Array.isArray(platform)) {
            // Is this right?
            const options = platform.map(pl => getFileFormatOptions(this.contentBuilder.document, pl));
            canPublishToFilter = platform.map(pl => pl.name);
            const imageOptions = {
                defaultFormat: options[0].defaultFormat,
                formats: options.map(option => option.formats).flat(),
            };
            return {
                canPublishToFilter,
                imageOptions,
            };
        }
        if (FilterableExportPlatforms.indexOf(platform!) > -1) {
            canPublishToFilter = [platform!.name];
        }

        const fileFormatOptions: FileFormatOptions = getFileFormatOptions(this.contentBuilder.document, platform!);

        return {
            canPublishToFilter,
            imageOptions: fileFormatOptions,
        };
    }

    private prepareImageAndGoToPublish(): IPromise<void> {
        return this.prepareImage().then((exportData: ExportData[]) => {
            if (this.platform === this.ExportPlatforms.Local) {
                return this.exportLocalImage(exportData);
                // TODO: finish
            } else if (ExportPlatformsArray.indexOf(this.platform) > -1) {
                this.setView(this.VIEWS.PUBLISH_TO_PLATFORM);
                if (exportData.length === 1) {
                    this.exportData = exportData[0]; // Dodgy way of passing data to nested controller.
                }
                this.printPublishData = {
                    builderDocument: this.contentBuilder.document,
                    exclusivePrintProvider: this.exclusivePrintProvider,
                    fileFormatChoice: this.fileFormatChoice!,
                    location: this.location,
                    plannerDetails: this.plannerDetails,
                    templateId: this.templateId,
                    textSubstitutionValues: this.contentBuilder.textSubstitutionValues,
                };
            } else if (this.platform === ExportPlatforms.Multiple.FacebookAndInstagram) {
                this.setView(this.VIEWS.PUBLISH_TO_PLATFORM);
            } else {
                // eslint-disable-next-line no-console
                console.log('Not implemented yet.');
            }
            return undefined;
        });
    }

    private getScaledImageData(): IPromise<ExportData> {
        return this.$q.resolve().then(async (): Promise<ExportData> => {
            const exportedData = await this.contentBuilder.exportAsImage(this.fileFormatChoice || undefined);
            return exportedData;
        });
    }

    private async getScaledMultiImageData(): Promise<ExportData[]> {
        const exportedData = await this.contentBuilder.exportMultiImageTemplateAsImages(
            this.fileFormatChoice || undefined,
        );
        return exportedData ?? [];
    }

    private async exportLocalImage(exportData: ExportData[]) {
        if (exportData.length === 0) {
            throw new Error('No export data found.');
        }
        return this.downloadLocalImage(exportData)
            .then(() =>
                // TODO: translate the "handleRemoteSimple" description
                this.interactionUtils.handleRemoteSimple(
                    this,
                    'save image locally',
                    'localImagePublish',
                    this.localPublishService.publishLocal(this.location.id, {
                        builderTemplateId: this.templateId,
                        plannerId: this.plannerDetails ? this.plannerDetails.id : null,
                    }),
                ),
            )
            .then(() => this.builderPublished(null, new PublishResult(ExportPlatforms.Local, null)));
    }

    private async downloadLocalImage(exportData: ExportData[]): Promise<void> {
        if (exportData.length === 1) {
            const exportDataInstance = exportData[0];
            // Chromium issue #69227: Loading large URLs kills the browser
            // https://code.google.com/p/chromium/issues/detail?id=69227
            // Workaround: convert the data URL to a Blob, and use the Object URL as the link.
            const blob = dataUrlToBlob(exportDataInstance.dataUrl!);
            const fileName = this.generateImageFileName(exportDataInstance.imageFormat);
            const objectUrl = URL.createObjectURL(blob);

            return this.fileUtils.downloadFile(objectUrl, fileName).finally(() =>
                URL.revokeObjectURL(objectUrl),
            );
        } else {
            const zip = new JSZip();
            exportData.forEach((exportDataInstance, index) => {
                const blob = dataUrlToBlob(exportDataInstance.dataUrl!);
                const fileName = this.generateImageFileName(exportDataInstance.imageFormat, `Image ${index + 1}`);
                zip.file(fileName, blob, { base64: true });
            });
            const contentBlob = await zip.generateAsync({ type: 'blob' });
            FileSaver.saveAs(contentBlob, `${this.contentBuilder.document.title}.zip`);
        }
        return Promise.resolve();
    }

    private isExportingAnimation(): boolean {
        return (
            !!this.fileFormatChoice &&
            !!this.fileFormatChoice.imageFormat &&
            this.fileFormatChoice.imageFormat.name === 'GIF' &&
            !!this.contentBuilder.document.animation
        );
    }

    private writeAnimationToGif(): IPromise<ExportData> {
        // Bit dodge, since it's really a blob, and we might convert back to a blob later
        return this.gifWriterService
            .writeAnimationToDataUrl(this.contentBuilder, this.fileFormatChoice!)
            .then((dataUrl: string) => new ExportData(dataUrl, getFormatByName('GIF'), getFormatByName('GIF'), false));
    }

    private getScheduledTimeDisplayText(scheduledTime: Date) {
        return this.$filter('date')(scheduledTime, "HH:mm (Z) 'on' d MMM yyyy"); // TODO: translate/localise this
    }

    private goToGallery() {
        const search: {
            planner?: number;
        } = {};
        if (this.plannerDetails) {
            search.planner = this.plannerDetails.id;
        }
        this.$location.path('/builderTemplateGallery').search(search);
    }

    private setView(view: string) {
        this.view = view;
        this.scrollToTop();
    }

    private scrollToTop() {
        const element = document.getElementById('publishPageTop');
        if (!!element && element.scrollIntoView) {
            element.scrollIntoView();
        }
    }

    private goToPlanner() {
        this.$location.search({});
        this.$location.path('/planner');
    }

    private builderPublished(_event: IAngularEvent | null, publishResult: PublishResult) {
        this.publishResult = publishResult;

        if (this.publishResult.publishType && this.publishResult.publishType === this.publishTypes.Four51) {
            this.setView(this.VIEWS.PUBLISHED_TO_FOUR51);
        } else if (this.publishResult.publishType && this.publishResult.publishType === this.publishTypes.Stripe) {
            this.broadcastShoppingCartItemChangeEvent();
            this.setView(this.VIEWS.PUBLISHED_TO_SHOPPING_CART);
        } else {
            this.setView(this.VIEWS.PUBLISHED);
        }
    }

    private finish() {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_FINISH);
    }

    private async fetchIsInstagramDirectPublishingEnabled(): Promise<void> {
        const gqlClient = this.graphqlService.getClient();
        const result = await gqlClient.query<GetIsInstagramEnabled>({
            query: GET_IS_INSTAGRAM_ENABLED_QUERY,
        });
        if (result.errors) {
            this.sentryService.captureException('Failed to fetch "GET_IS_INSTAGRAM_ENABLED_QUERY" GraphQL query', {
                errors: result.errors,
            });
        } else {
            this.isInstagramDirectPublishingEnabled = result.data.config.features.publishing.instagramDirectPublishing;
        }
    }

    private async getFacebookInstagramMultiPublishEnabled(): Promise<void> {
        const gqlClient = this.graphqlService.getClient();
        const { data, errors } = await gqlClient.query<GetMultiPublishConfig>({
            query: GET_MULTIPUBLISH_ENABLED,
        });
        if (errors) {
            this.sentryService.captureException('Failed to fetch "GET_MULTIPUBLISH_ENABLED" GraphQL query', {
                errors,
            });
        } else {
            this.isFacebookInstagramMultipublishEnabled = data.config.features.publishing.facebookInstagram;
        }
    }

    private async getInstagramStoriesEnabled(): Promise<void> {
        const gqlClient = this.graphqlService.getClient();
        const { data, errors } = await gqlClient.query<GetInstagramStoriesEnabled>({
            query: GET_INSTAGRAM_STORIES_ENABLED,
        });
        if (errors) {
            this.sentryService.captureException(
                'Failed to fetch "GET_INSTAGRAM_STORIES_ENABLED" GraphQL query', {
                errors,
            });
        } else {
            this.isInstagramStoriesEnabled = data.config.features.publishing.instagramStories;
        }
    }

    private async fetchHasDirectPublishPermissions(): Promise<void> {
        const gqlClient = this.graphqlService.getClient();
        const result = await gqlClient.query<GetHasDirectPublishPermissionsForPublishController>({
            fetchPolicy: 'network-only',
            query: GET_HAS_DIRECT_PUBLISH_PERMISSIONS,
            variables: { locationId: this.location.graphqlId },
        });
        if (result.errors) {
            this.sentryService.captureException('Failed to fetch "GET_HAS_DIRECT_PUBLISH_PERMISSIONS" GraphQL query', {
                errors: result.errors,
            });
        } else {
            this.hasInstagramDirectPublishPermissions =
                result.data.location?.instagram.__typename === 'Instagram' &&
                result.data.location.instagram.hasDirectPublishPermissions;
        }
    }
}

angular.module('app').controller(MvContentBuilderPublishCtrl.SID, MvContentBuilderPublishCtrl);
