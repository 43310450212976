import {
    BuilderTemplateGalleryDto,
    linkToTemplate as defaultLinkToTemplate,
    linkToBuildableTemplatePublish,
    LocationId,
} from '@deltasierra/shared';
import {
    ExpressionBinding,
    ExpressionCallback,
    OneWayBinding,
    OptionalExpressionBinding,
    OptionalOneWayBinding,
    simpleComponent,
} from '../common/angularData';
import { GET_CONFIG_QUERY } from '../common/config';
import { GetConfig } from '../common/config/__graphqlTypes/GetConfig';
import { GraphqlService } from '../graphql/GraphqlService';
import { convertIdToUniversalNodeId } from '../graphql/utils';
import { MvLocation } from '../locations/mvLocation';
import { TemplateGroup, TemplateGroupTypeEnum } from './templateGroup';

export class TemplateGroupComponentController {
    public static readonly $inject: string[] = [GraphqlService.SID, MvLocation.SID];

    public constructor(
        private readonly graphqlService: GraphqlService,
        private readonly mvLocation: MvLocation,
    ) {}

    // Inputs
    public group!: TemplateGroup;

    public isFaded!: boolean;

    public plannerId?: number;

    public collectionId?: number;

    public currentLocationId?: LocationId;

    public shouldShowTemplateMenu!: boolean;

    public environmentConfig?: GetConfig;

    public onClickTemplateContainer?: ExpressionCallback<Record<string, unknown>>;

    public onClickTemplateGroupHeading?: ExpressionCallback<{ group: TemplateGroup }>;

    public onLastChildInView!: ExpressionCallback<{ group: TemplateGroup }>;

    public linkToTemplate?: ExpressionCallback<{ template: BuilderTemplateGalleryDto }, string>;

    public async $onInit(): Promise<void> {
        this.environmentConfig = await this.getEnvironmentConfig();
        this.currentLocationId = await this.mvLocation.getLocationIdOrUpdateWithDefault();
    }

    public clickTemplateGroupHeading(group: TemplateGroup): void {
        if (this.onClickTemplateGroupHeading) {
            this.onClickTemplateGroupHeading({
                group,
            });
        }
    }

    public shouldShouldGroupTemplates(group: TemplateGroup): boolean {
        switch (group.groupType.type) {
            case TemplateGroupTypeEnum.SearchResults:
                return (
                    group.fetchTemplates.isPending() || group.fetchTemplates.isRunning() || group.templates.length > 0
                );
            default:
                return true;
        }
    }

    public lastChildInViewCallback(group: TemplateGroup): void {
        this.onLastChildInView({
            group,
        });
    }

    public clickTemplateContainer(): void {
        if (this.onClickTemplateContainer) {
            this.onClickTemplateContainer({});
        }
    }

    public callLinkToTemplate(
        template: BuilderTemplateGalleryDto,
    ): ExpressionCallback<{ template: BuilderTemplateGalleryDto; }, string> | string {
        if (this.linkToTemplate) {
            return this.linkToTemplate({ template });
        }

        if (template.currentRevisionId) {
            if (!this.currentLocationId) {
                return '';
            }
            return linkToBuildableTemplatePublish(
                template.documentFormat,
                template.currentRevisionId,
                convertIdToUniversalNodeId('client', template.clientId),
                convertIdToUniversalNodeId('location', this.currentLocationId),
                this.environmentConfig?.config.appFrontendUrl,
            );
        }

        return defaultLinkToTemplate(
            template.documentFormat,
            template.id,
            this.plannerId,
            '',
            this.collectionId,
        );
    }

    private async getEnvironmentConfig(): Promise<GetConfig> {
        const gqlClient = this.graphqlService.getClient();

        const configResult = await gqlClient.query<GetConfig>({
            fetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true,
            query: GET_CONFIG_QUERY,
        });

        if (configResult.errors) {
            throw new Error('Failed to fetch config');
        }

        return configResult.data;
    }
}

export const dsTemplateGroupSID = 'dsTemplateGroup';

export const dsTemplateGroupConfig: ng.IDirective<ng.IScope> = simpleComponent(
    TemplateGroupComponentController,
    '/partials/templateGallery/templateGroupComponent',
    {
        collectionId: OptionalOneWayBinding,
        group: OneWayBinding,
        isFaded: OneWayBinding,
        linkToTemplate: OptionalExpressionBinding,
        onClickTemplateContainer: OptionalExpressionBinding,
        onClickTemplateGroupHeading: OptionalExpressionBinding,
        onLastChildInView: ExpressionBinding,
        plannerId: OptionalOneWayBinding,
        shouldShowTemplateMenu: OneWayBinding,
    },
);

angular.module('app').directive(dsTemplateGroupSID, [() => dsTemplateGroupConfig]);
