import { LocationAccessCheckPlatformNames, ILocationAccessCheckPlatformName, ClientId, PublishableService, ServiceName, Platform, BuilderTemplateFormat, LocationId } from '@deltasierra/shared';


import { noop } from '@deltasierra/object-utilities';
import { contains } from '@deltasierra/array-utilities';
import { $qSID } from '../../common/angularData';
import { Future } from '../../common/Future';
import { MvClient } from '../../clients/mvClient';
import { MvAuth } from '../../account/mvAuth';
import { InteractionUtils } from '../../common/interactionUtils';

export type AvailableAuthorisedPlatformFutures = {
    [K in ILocationAccessCheckPlatformName]?: Future<void, unknown>;
};

export type AvailableAuthorisedPlatforms = {
    [K in ILocationAccessCheckPlatformName]?: boolean;
};

/**
 * Availability = is the platform enabled for the client?
 * Access = is the platform authorised and configured for the location?
 */
export class PlatformAvailabilityAndAccess {
    protected readonly accessiblePlatforms: AvailableAuthorisedPlatforms = {};

    protected readonly futures: AvailableAuthorisedPlatformFutures;

    // eslint-disable-next-line max-params
    public constructor(
        protected readonly $q: angular.IQService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvAuth: MvAuth,
        protected readonly availablePlatforms: ServiceName[],
        platformsToCheck: ILocationAccessCheckPlatformName[],
        locationId: LocationId,
    ) {
        this.futures = this.defineFutures(platformsToCheck, locationId);
    }

    public isAnyPlatformAccessCheckLoading(): boolean {
        for (const platform in this.futures) {
            if (Object.prototype.hasOwnProperty.call(this.futures, platform)) {
                const future = this.futures[platform as keyof AvailableAuthorisedPlatformFutures];
                if (future && future.isRunning()) {
                    return true;
                }
            }
        }
        return false;
    }

    public isAnyPlatformAccessCheckNegative(): boolean {
        for (const platform in this.accessiblePlatforms) {
            if (Object.prototype.hasOwnProperty.call(this.accessiblePlatforms, platform)) {
                const hasAccess = this.accessiblePlatforms[platform as keyof AvailableAuthorisedPlatforms];
                if (hasAccess === false) {
                    // Use strict equality so we don't treat "undefined" as a negative value
                    return true;
                }
            }
        }
        return false;
    }

    public isPlatformAvailable(platformName: PublishableService): boolean {
        return contains(this.availablePlatforms, platformName);
    }

    public hasPlatformAccess(platformName: ILocationAccessCheckPlatformName): boolean {
        return this.accessiblePlatforms[platformName] === true;
    }

    public hasAnyPlatforms(): boolean {
        return this.availablePlatforms.length > 0;
    }

    public run(): ng.IPromise<void> {
        const promises = [];
        for (const platform in this.futures) {
            if (Object.prototype.hasOwnProperty.call(this.futures, platform)) {
                const future = this.futures[platform as keyof AvailableAuthorisedPlatformFutures];
                if (future) {
                    if (future.isRunning() && future.promise) {
                        promises.push(future.promise);
                    } else {
                        promises.push(future.run({}));
                    }
                }
            }
        }
        return this.$q.all(promises).then(noop);
    }

    protected defineFutures(
        platformsToCheck: ILocationAccessCheckPlatformName[],
        locationId: LocationId,
    ): AvailableAuthorisedPlatformFutures {
        const futures: AvailableAuthorisedPlatformFutures = {};
        for (const platform of platformsToCheck) {
            const future = this.interactionUtils.createFuture(`fetch access for ${platform}`, () =>
                this.mvAuth.isAuthorizedAndConfigured(platform, locationId).then((result: boolean) => {
                    this.accessiblePlatforms[platform] = result;
                }),
            );
            futures[platform] = future;
        }
        return futures;
    }
}

export class PublishingPlatformsService {
    public static readonly SID = 'PublishingPlatformsService';

    public static readonly $inject: string[] = [$qSID, InteractionUtils.SID, MvAuth.SID, MvClient.SID];

    public constructor(
        protected readonly $q: ng.IQService,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvAuth: MvAuth,
        protected readonly mvClient: MvClient,
    ) {}

    public fetchPlatformAccess(
        locationId: LocationId,
        clientId: ClientId,
        builderTemplateFormats: BuilderTemplateFormat[],
        isAnimation: boolean,
    ): ng.IPromise<PlatformAvailabilityAndAccess> {
        return this.mvClient
            .getPlatforms(clientId)
            .then(platforms => this.initialisePlatformAccessChecks(platforms, locationId, builderTemplateFormats, isAnimation));
    }

    protected canPublishToPlatform(
        serviceName: PublishableService,
        availablePlatforms: Platform[],
        builderTemplateFormats: BuilderTemplateFormat[],
        isAnimation: boolean,
    ): boolean {
        const isPlatformAvailableForClient = availablePlatforms.some(platform => platform.name === serviceName);
        if (isPlatformAvailableForClient) {
            return builderTemplateFormats.some(format => format.canPublishTo === serviceName && (!isAnimation || format.animatable));
        }
        return false;
    }

    protected initialisePlatformAccessChecks(
        platforms: Platform[],
        locationId: LocationId,
        builderTemplateFormats: BuilderTemplateFormat[],
        isAnimation: boolean,
    ): PlatformAvailabilityAndAccess {
        const platformsToCheck = LocationAccessCheckPlatformNames.filter(platformName =>
            this.canPublishToPlatform(platformName, platforms, builderTemplateFormats, isAnimation),
        );

        const platformNames = platforms
            .filter(platform =>
                this.canPublishToPlatform(platform.name as PublishableService, platforms, builderTemplateFormats, isAnimation),
            )
            .map(platform => platform.name);
        return new PlatformAvailabilityAndAccess(this.$q, this.interactionUtils, this.mvAuth, platformNames, platformsToCheck, locationId);
    }
}

angular.module('app').service(PublishingPlatformsService.SID, PublishingPlatformsService);
