import { IHttpPromise } from 'angular';
import { GetBusinessesResponseEntry,
    GetClubsResponseEntry,
    GetProspectsReportsResponseEntry,
 AssignedLocation, ClientId, Omit, ReportableService } from '@deltasierra/shared';


import { $httpSID } from '../../common/angularData';
import { MvLocation } from '../../locations/mvLocation';
import { BaseLocationStatsService, CanConfigureResponse } from './common';
import IHttpService = angular.IHttpService;

export type FitwareStats = GetProspectsReportsResponseEntry; // TODO

export type CombinedFitwareStats = Omit<
    FitwareStats,
    | 'Code'
    | 'ContactRatio'
    | 'Membership12MonthRatio'
    | 'Name'
    | 'TotalCloseRatio'
    | 'TourCloseRatio'
    | 'TourShowRatio'
    | 'Type'
    | 'WalkInCloseRatio'
> & {
    Name: null;
    Code: null;
    Type: null;
    ContactRatio: string | null;
    TourShowRatio: string | null;
    TourCloseRatio: string | null;
    WalkInCloseRatio: string | null;
    TotalCloseRatio: string | null;
    Membership12MonthRatio: string | null;
};

export type FitwareClub = Pick<GetClubsResponseEntry, 'AccountID' | 'Brand' | 'ClubName' | 'ClubNumber' | 'Email' | 'StateISOAlpha2'>;

export class FitwareStatsService extends BaseLocationStatsService<FitwareStats, CombinedFitwareStats> {
    public static readonly SID = 'fitwareStatsService';

    public readonly serviceName: ReportableService = 'fitware';

    public readonly displayName: string = 'Fitware';

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [$httpSID, MvLocation.SID];

    public constructor($http: IHttpService, protected readonly mvLocation: MvLocation) {
        super($http);
    }

    public getBusinesses(): IHttpPromise<GetBusinessesResponseEntry[]> {
        return this.$http.get<GetBusinessesResponseEntry[]>(`/api/stats/${this.serviceName}/businesses`);
    }

    public getClubs(clientId: ClientId): IHttpPromise<FitwareClub[]> {
        return this.$http.get<FitwareClub[]>(`/api/stats/${this.serviceName}/client/${clientId}/clubs`);
    }

    public isPlatformConfigured(location: AssignedLocation): boolean {
        return location.fitwareClubNumber !== null;
    }

    public canConfigureClient(clientId: ClientId): angular.IPromise<boolean> {
        return this.$http
            .get<CanConfigureResponse>(`/api/auth/${this.serviceName}/client/${clientId}/canConfigure`) // Hmm, auth with stats?
            .then((res: ng.IHttpPromiseCallbackArg<CanConfigureResponse>) => res.data!.canConfigure);
    }

    public combineStats(stats: CombinedFitwareStats[]): CombinedFitwareStats {
        const initial: CombinedFitwareStats = {
            AppointmentsBookedForFutureDate: 0,
            AppointmentsBookedForTomorrow: 0,
            AppointmentsBookedSameDay: 0,
            BookedTotal: 0,
            Code: null,
            ContactAttempts: 0,
            ContactRatio: null,
            Contacts: 0,
            EntityData: [],
            Membership12Month: 0,
            Membership12MonthRatio: null,
            MembershipNoContract: 0,
            Name: null,
            NewLeads: 0,
            PersonalTrainingSold: 0,
            ReferralsPerSale: 0,
            ReferralsPos: 0,
            ServiceCalls: 0,
            Total: 0,
            TotalCloseRatio: null,
            TotalSale: 0,
            TourCloseRatio: null,
            TourSale: 0,
            TourShowRatio: null,
            TourShowUp: 0,
            TourToday: 0,
            TourTotal: 0,
            Type: null,
            WalkInCloseRatio: null,
            WalkInSale: 0,
            WalkInTotal: 0,
        };
        const reduced = stats.reduce(this.reduceStats.bind(this), initial);
        this.setRatios(reduced);
        return reduced;
    }

    private reduceStats(
        previousValue: CombinedFitwareStats,
        currentValue: CombinedFitwareStats,
        currentIndex: number,
        array: CombinedFitwareStats[],
    ): CombinedFitwareStats {
        const result: CombinedFitwareStats = {
            AppointmentsBookedForFutureDate: previousValue.AppointmentsBookedForFutureDate + currentValue.AppointmentsBookedForFutureDate,
            AppointmentsBookedForTomorrow: previousValue.AppointmentsBookedForTomorrow + currentValue.AppointmentsBookedForTomorrow,
            AppointmentsBookedSameDay: previousValue.AppointmentsBookedSameDay + currentValue.AppointmentsBookedSameDay,
            BookedTotal: previousValue.BookedTotal + currentValue.BookedTotal,
            Code: null,
            ContactAttempts: previousValue.ContactAttempts + currentValue.ContactAttempts,
            ContactRatio: null,
            Contacts: previousValue.Contacts + currentValue.Contacts,
            EntityData: [],
            Membership12Month: previousValue.Membership12Month + currentValue.Membership12Month,
            Membership12MonthRatio: null,
            MembershipNoContract: previousValue.MembershipNoContract + currentValue.MembershipNoContract,
            Name: null,
            NewLeads: previousValue.NewLeads + currentValue.NewLeads,
            PersonalTrainingSold: previousValue.PersonalTrainingSold + currentValue.PersonalTrainingSold,
            ReferralsPerSale: previousValue.ReferralsPerSale + currentValue.ReferralsPerSale,
            ReferralsPos: previousValue.ReferralsPos + currentValue.ReferralsPos,
            ServiceCalls: previousValue.ServiceCalls + currentValue.ServiceCalls,
            Total: previousValue.Total + currentValue.Total,
            TotalCloseRatio: null,
            TotalSale: previousValue.TotalSale + currentValue.TotalSale,
            TourCloseRatio: null,
            TourSale: previousValue.TourSale + currentValue.TourSale,
            TourShowRatio: null,
            TourShowUp: previousValue.TourShowUp + currentValue.TourShowUp,
            TourToday: previousValue.TourToday + currentValue.TourToday,
            TourTotal: previousValue.TourTotal + currentValue.TourTotal,
            Type: null,
            WalkInCloseRatio: null,
            WalkInSale: previousValue.WalkInSale + currentValue.WalkInSale,
            WalkInTotal: previousValue.WalkInTotal + currentValue.WalkInTotal,
        };
        return result;
    }

    private formatRatio(numerator: number, denominator: number): string {
        const ratio = denominator ? numerator / denominator : 0;
        return `${Math.round(ratio * 100)}%`;
    }

    private setRatios(stats: CombinedFitwareStats): void {
        stats.ContactRatio = this.formatRatio(stats.Contacts, stats.ContactAttempts);
        stats.TourShowRatio = this.formatRatio(stats.TourShowUp, stats.TourToday);
        stats.TourCloseRatio = this.formatRatio(stats.TourSale, stats.TourShowUp);
        stats.WalkInCloseRatio = this.formatRatio(stats.WalkInSale, stats.WalkInTotal);
        stats.TotalCloseRatio = this.formatRatio(stats.TotalSale, stats.Total);
        // No idea what this is meant to be
        // Stats.Membership12MonthRatio = `${ Math.round(stats.Membership12Month / stats.MembershipNoContract * 100) }%`;
    }
}

angular.module('app').service(FitwareStatsService.SID, FitwareStatsService);
