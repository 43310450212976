import { AssignedLocation, StatsApi, ReportableService, BatchStatsEntry, FitwareMemberEngagerStatsForLocation } from '@deltasierra/shared';


import { $httpSID } from '../../common/angularData';
import { SingleSearchCriteria } from '../../reports/reportPage/controller';
import { invokeApiRouteReturningHttpPromise } from '../../common/httpUtils';
import { MvLocation } from '../../locations/mvLocation';
import { BaseLocationStatsService } from './common';
import IHttpService = angular.IHttpService;

export interface GroupedFitwareMemberEngagerStats {
    entries: BatchStatsEntry[];
    totals: BatchStatsEntry;
}

export class FitwareMemberEngagerStatsService extends BaseLocationStatsService<
    FitwareMemberEngagerStatsForLocation,
    GroupedFitwareMemberEngagerStats
> {
    public static readonly SID: string = 'fitwareMemberEngagerStatsService';

    public readonly serviceName: ReportableService | 'fitwareMemberEngager' | 'internal' = 'fitwareMemberEngager';

    public readonly displayName: string = 'Fitware Member Engager';

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [$httpSID, MvLocation.SID];

    public constructor($http: IHttpService, protected readonly mvLocation: MvLocation) {
        super($http);
    }

    public getStatsForLocation(searchCriteria: SingleSearchCriteria): angular.IHttpPromise<FitwareMemberEngagerStatsForLocation> {
        return invokeApiRouteReturningHttpPromise(this.$http, StatsApi.getFitwareMemberEngagerStats, {
            body: undefined,
            params: { location_id: searchCriteria.location.id },
            query: {
                endDate: searchCriteria.endDate,
                startDate: searchCriteria.startDate,
            },
        });
    }

    public isPlatformConfigured(location: AssignedLocation): boolean {
        return location.fitwareClubNumber !== null;
    }

    public combineStats(stats: GroupedFitwareMemberEngagerStats[]): GroupedFitwareMemberEngagerStats {
        const flattened: BatchStatsEntry[] = [];
        for (const group of stats) {
            flattened.push(group.totals);
        }
        return {
            entries: [],
            totals: this.combineStatsForBatchEntries(flattened),
        };
    }

    public combineStatsForBatchEntries(stats: BatchStatsEntry[]): BatchStatsEntry {
        const initial: BatchStatsEntry = {
            date: new Date(),
            memberListNames: [],
            recipientStats: {
                bounces: 0,
                clicks: 0,
                opens: 0,
                sent: 0,
                spamReports: 0,
                unsubscribes: 0,
            },
            title: '',
        };
        return stats.reduce(this.reduceStatsForBatchEntry, initial);
    }

    private reduceStatsForBatchEntry = (previousValue: BatchStatsEntry, currentValue: BatchStatsEntry): BatchStatsEntry => {
        const result: BatchStatsEntry = {
            date: previousValue.date,
            memberListNames: previousValue.memberListNames,
            recipientStats: {
                bounces: previousValue.recipientStats.bounces + currentValue.recipientStats.bounces,
                clicks: previousValue.recipientStats.clicks + currentValue.recipientStats.clicks,
                opens: previousValue.recipientStats.opens + currentValue.recipientStats.opens,
                sent: previousValue.recipientStats.sent + currentValue.recipientStats.sent,
                spamReports: previousValue.recipientStats.spamReports + currentValue.recipientStats.spamReports,
                unsubscribes: previousValue.recipientStats.unsubscribes + currentValue.recipientStats.unsubscribes,
            },
            title: previousValue.title,
        };
        return result;
    };
}

angular.module('app').service(FitwareMemberEngagerStatsService.SID, FitwareMemberEngagerStatsService);
