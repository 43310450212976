import { gql, useApolloClient, useQuery, Reference } from '@apollo/client';
import {
    DSLoaderButton,
    DSTable,
    DSTableBody,
    DSTableCell,
    DSTableHead,
    DSTableRow,
    DSTypography,
    Translate,
} from '@deltasierra/components';
import { PublishableService, t } from '@deltasierra/shared';
import * as React from 'react';
import { PostArtifactState } from '../../../../../__graphqlTypes/globalTypes';
import { ScheduledPostRow } from '../ScheduledPostRow';
import { canDeletePublishedPost } from '../utils';
import {
    GetAllPostsForLocation,
    GetAllPostsForLocationVariables,
    GetAllPostsForLocation_location_platforms_scheduledPosts_edges_node_timestamps as Timestamps,
    GetAllPostsForLocation_location_platforms_scheduledPosts_pageInfo as PageInfo,
} from './__graphqlTypes/GetAllPostsForLocation';
import { PlatformFragmentForScheduledPostTable } from './__graphqlTypes/PlatformFragmentForScheduledPostTable';

type NormalizedScheduledPosts = {
    edges: Array<{ __typename: string; cursor: string; node: Reference }>;
    pageInfo: PageInfo;
};

export const GET_POSTS_FOR_SCHEDULED_PAGE = gql`
    query GetAllPostsForLocation(
        $locationId: ID!
        $firstPast: Int!
        $cursorPast: String
        $firstSched: Int!
        $cursorSched: String
        $platform: String!
    ) {
        location(id: $locationId) {
            __typename
            id
            platforms {
                __typename
                id
                pastPosts(first: $firstPast, after: $cursorPast, platform: $platform) {
                    edges {
                        node {
                            __typename
                            id
                            ...ScheduledPostsRowFragment
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                        startCursor
                    }
                }
                scheduledPosts(first: $firstSched, after: $cursorSched, platform: $platform) {
                    edges {
                        node {
                            __typename
                            id
                            ...ScheduledPostsRowFragment
                        }
                    }
                    pageInfo {
                        endCursor
                        hasNextPage
                        startCursor
                    }
                }
            }
        }
    }
    ${ScheduledPostRow.fragments.post}
`;

const platformFragment = gql`
    fragment PlatformFragmentForScheduledPostTable on Platform {
        id
        name
        displayName
    }
`;

/**
 * This hook is purely a headless component to be used by the mobile/desktop versions of the Scheduled Post list.
 * It isn't particularly reusable
 *
 * @param locationId - Location ID filter
 * @param platform - Platform filter
 * @param first - The number of items to fetch
 * @returns An object container the scheduled posts, metadata, and refetch functions
 */
const useGetScheduledPosts = (locationId: string, platform: string, first = 2) => {
    const [shouldShowOlder, setShouldShowOlder] = React.useState(false);
    const result = useQuery<GetAllPostsForLocation, GetAllPostsForLocationVariables>(GET_POSTS_FOR_SCHEDULED_PAGE, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        variables: { firstPast: 2, firstSched: first, locationId, platform },
    });

    /*
     * There is currently an issue in Apollo Client v3.4.15 where setting `notifyOnNetworkStatusChange` to true causes
     * refetch to complete with partial data. this is why we "destructure" the result here.
     *
     * @see https://digitalstack.atlassian.net/browse/DS-7412
     */
    const platforms = result.data?.location?.platforms;
    const isLoading = result.loading;

    React.useEffect(() => {
        setShouldShowOlder(false);
    }, [platform, locationId]);

    const fetchPrev = () => {
        if (shouldShowOlder) {
            if (result.data?.location?.platforms.pastPosts.pageInfo.hasNextPage) {
                void result.fetchMore({
                    variables: {
                        cursorPast: result.data.location.platforms.pastPosts.pageInfo.endCursor,
                        firstPast: first,
                    },
                });
            }
        } else {
            setShouldShowOlder(true);
        }
    };

    const fetchMore = () => {
        if (result.data?.location?.platforms.scheduledPosts.pageInfo.hasNextPage) {
            void result.fetchMore({
                variables: {
                    cursorSched: result.data?.location?.platforms.scheduledPosts.pageInfo.endCursor,
                    firstSched: first,
                },
            });
        }
    };

    let hasPrevPage: boolean;
    if (!platforms?.pastPosts) {
        hasPrevPage = false;
    } else if (!shouldShowOlder && platforms.pastPosts.edges.length > 0) {
        hasPrevPage = true;
    } else {
        hasPrevPage = platforms.pastPosts.pageInfo.hasNextPage ?? false;
    }

    return {
        fetchMore,
        fetchPrev,
        hasNextPage: platforms?.scheduledPosts.pageInfo.hasNextPage ?? false,
        hasPrevPage,
        loading: isLoading,
        platformId: platforms?.id,
        posts: shouldShowOlder
            ? [
                  ...(platforms?.pastPosts.edges.map(({ node }) => node) ?? []).reverse(),
                  ...(platforms?.scheduledPosts.edges.map(({ node }) => node) ?? []),
              ]
            : platforms?.scheduledPosts.edges.map(({ node }) => node) ?? [],
    };
};

export type ScheduledPostTableProps = {
    locationId: string;
    platform: PlatformFragmentForScheduledPostTable;
};

export function ScheduledPostTable({ locationId, platform }: ScheduledPostTableProps): JSX.Element {
    const { fetchMore, fetchPrev, hasNextPage, hasPrevPage, loading, platformId, posts } = useGetScheduledPosts(
        locationId,
        platform.name,
    );
    const client = useApolloClient();

    const reorderPostsAfterReschedule = React.useCallback(() => {
        // Apollo Cache logic to re-order scheduled posts after rescheduling
        // Passed down as a callback into ReschedulePostModal to be called upon successful reschedule
        const cacheId = client.cache.identify({ __typename: 'ConnectedPlatforms', id: platformId });
        client.cache.modify<{ scheduledPosts: any }>({
            fields: {
                scheduledPosts(existing: NormalizedScheduledPosts, { readField }): any {
                    const sortedEdges = [...existing.edges];
                    sortedEdges.sort((edgeOne, edgeTwo) => {
                        const timestampsOne: Timestamps | undefined = readField('timestamps', edgeOne.node);
                        const timestampsTwo: Timestamps | undefined = readField('timestamps', edgeTwo.node);
                        if (!timestampsOne?.scheduled || !timestampsTwo?.scheduled) {
                            return 0;
                        }
                        return (
                            new Date(timestampsOne.scheduled).getTime() - new Date(timestampsTwo.scheduled).getTime()
                        );
                    });
                    return { ...existing, edges: sortedEdges };
                },
            },
            id: cacheId,
        });
    }, [platformId, client]);

    return (
        <DSTable size="small" style={{ marginTop: '14px' }}>
            <DSTableHead>
                <DSTableRow>
                    <DSTableCell align="center">{t('SCHEDULE.PAGE.TABLE.HEADERS.POST')}</DSTableCell>
                    <DSTableCell align="left">{t('SCHEDULE.PAGE.TABLE.HEADERS.CAPTION')}</DSTableCell>
                    <DSTableCell align="right">{t('SCHEDULE.PAGE.TABLE.HEADERS.LOCATIONS')}</DSTableCell>
                    <DSTableCell align="center">{t('SCHEDULE.PAGE.TABLE.HEADERS.TIME')}</DSTableCell>
                    <DSTableCell align="center">{t('SCHEDULE.PAGE.TABLE.HEADERS.ACTION')}</DSTableCell>
                </DSTableRow>
            </DSTableHead>
            <DSTableBody>
                <DSTableRow>
                    <DSTableCell colSpan={5} style={{ border: 'none' }}>
                        <DSLoaderButton
                            color="primary"
                            disabled={!hasPrevPage}
                            fullWidth
                            loading={loading}
                            variant="outlined"
                            onClick={fetchPrev}
                        >
                            {t('SCHEDULE.PAGE.TABLE.LOAD_OLDER_POSTS')}
                        </DSLoaderButton>
                    </DSTableCell>
                </DSTableRow>
                {!loading && posts.length === 0 && (
                    <DSTableRow>
                        <DSTableCell colSpan={5} style={{ border: 'none' }}>
                            <DSTypography align="center" component="p" style={{ padding: '16px 0px' }} variant="body1">
                                <Translate
                                    keyId="SCHEDULE.PAGE.TABLE.NO_SCHEDULED_POSTS_FOUND_FOR_PLATFORM"
                                    options={{ platform: platform.displayName }}
                                />
                            </DSTypography>
                        </DSTableCell>
                    </DSTableRow>
                )}
                {posts.map(post => (
                    <ScheduledPostRow
                        hideActions={
                            // If it's scheduled for publish, always show context menu
                            post.status.state !== PostArtifactState.SCHEDULED ||
                            // If it's already published, check if we can delete it
                            (post.status.state !== PostArtifactState.SCHEDULED &&
                                !canDeletePublishedPost(post.platform.name as PublishableService)) ||
                            // If scheduled time is less than the current time, hide context menu as post has gone out
                            (!!post.timestamps.scheduled && new Date(post.timestamps.scheduled) <= new Date())
                        }
                        key={post.id}
                        post={post}
                        style={{ opacity: loading ? 0.5 : 1 }}
                        onReschedule={reorderPostsAfterReschedule}
                    />
                ))}
                <DSTableRow>
                    <DSTableCell colSpan={5} style={{ border: 'none' }}>
                        <DSLoaderButton
                            color="primary"
                            disabled={!hasNextPage}
                            fullWidth
                            loading={loading}
                            variant="outlined"
                            onClick={fetchMore}
                        >
                            {t('SCHEDULE.PAGE.TABLE.LOAD_NEWER_POSTS')}
                        </DSLoaderButton>
                    </DSTableCell>
                </DSTableRow>
            </DSTableBody>
        </DSTable>
    );
}
ScheduledPostTable.displayName = 'ScheduledPostTable';
ScheduledPostTable.fragments = { platform: platformFragment };
