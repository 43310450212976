import { rollingMean } from '@deltasierra/math-utilities';
import { ReportableService, InstagramStatsDto } from '@deltasierra/shared';

import { $httpSID } from '../../common/angularData';
import { BaseLocationStatsService } from './common';
import IHttpService = angular.IHttpService;

export class InstagramStatsService extends BaseLocationStatsService<InstagramStatsDto, InstagramStatsDto> {
    public static readonly SID = 'instagramStatsService';

    public readonly serviceName: ReportableService = 'instagram';

    public readonly displayName: string = 'Instagram';

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [$httpSID];

    public constructor($http: IHttpService) {
        super($http);
    }

    public combineStats(stats: InstagramStatsDto[]): InstagramStatsDto {
        const initial = {
            followers: {
                count: 0,
                growth: 0,
                new: 0,
                previous: 0,
            },
            media: {
                count: 0,
                new: 0,
                previous: 0,
            },
        };
        return stats.reduce(this.reduceStats.bind(this), initial);
    }

    private reduceStats(
        previousValue: InstagramStatsDto,
        currentValue: InstagramStatsDto,
        currentIndex: number,
        array: InstagramStatsDto[],
    ): InstagramStatsDto {
        return {
            followers: {
                count: previousValue.followers.count + currentValue.followers.count,
                growth: rollingMean(previousValue.followers.growth, currentValue.followers.growth, currentIndex),
                new: previousValue.followers.new + currentValue.followers.new,
                previous: previousValue.followers.previous + currentValue.followers.previous,
            },
            media: {
                count: previousValue.media.count + currentValue.media.count,
                new: previousValue.media.new + currentValue.media.new,
                previous: previousValue.media.previous + currentValue.media.previous,
            },
        };
    }
}

angular.module('app').service('instagramStatsService', InstagramStatsService);
